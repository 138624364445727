import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  mainPurple,
  mainCyan,
  mainWhite,
  screen,
  transHover,
} from "../../components/variables";
import ArrowPlain from "../../images/svg/arrow-plain.svg";

const Wrapper = styled(motion.button)`
  background: ${(props) => (props.bgWhite ? mainWhite : mainPurple)};
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 20px;
  border: 2px ${mainPurple} solid;
  border-radius: 30px;
  padding: 16px 22px;
  outline: none;
  color: ${(props) => (props.bgWhite ? mainPurple : mainWhite)};
  transition: background 0.2s ease-out;
  max-width: max-content;
  @media ${screen.xxsmall} {
    padding: 18px 26px;
  }
  @media ${screen.withCursor} {
    &:hover {
      background: ${(props) => (props.bgWhite ? mainCyan : "none")};
      color: ${(props) => (props.bgWhite ? mainWhite : mainPurple)};
    }
    &:hover .arrow svg .arrow-plain-inner {
      fill: ${(props) => (props.bgWhite ? mainWhite : mainPurple)};
    }
  }

  .label {
    letter-spacing: 2px;
    font-weight: 900;
    font-size: 1.06rem;
    @media ${screen.xxsmall} {
      font-size: 1.08rem;
    }
    @media ${screen.small} {
      font-size: 1.1rem;
    }
  }

  .arrow {
    svg {
      width: 30px;
      margin: 0 0 -1px 8px;
      @media ${screen.xxsmall} {
        margin: 0 0 -2px 10px;
      }

      .arrow-plain-inner {
        transition: ${transHover};
        fill: ${(props) => (props.bgWhite ? mainPurple : mainWhite)};
      }
    }
  }
`;

const ButtonFilled = ({ className, onClick, label, bgWhite }) => {
  return (
    <Wrapper
      className={className}
      onClick={onClick}
      whileTap={{ scale: 0.98 }}
      bgWhite={bgWhite}
      aria-label={label || "Go"}
    >
      <span className="label">{label || "LABEL"}</span>
      <span className="arrow">
        <ArrowPlain />
      </span>
    </Wrapper>
  );
};

export default ButtonFilled;
