import React, { useState, useRef } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { ScrollLocky } from "react-scroll-locky";
import { motion, AnimatePresence } from "framer-motion";
import IconArrowSharpDown from "../../images/svg/arrow-sharp-down.svg";
import getHtmlEmailTemplate from "./html_template";
import {
  dirtyWhite,
  mainCyan,
  mainBlack,
  transHover,
  subFont,
  lightPurple,
  mainOrange,
  screen,
  emailRegex,
} from "../../components/variables";
import IconArrowSquiggly from "../../images/svg/arrow-bottom-squiggly.svg";
import { encode } from "../../helpers";
import axios from "axios";
import ButtonFilled from "../buttons/button-filled";
import IconClose from "../../images/svg/icon-close.svg";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { IoIosPaw } from "react-icons/io";
import IconSpinner from "../../images/svg/icon-loading.svg";
import IconCheck from "../../images/svg/icon-check.svg";

// motion framer animation props value
const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const item = {
  show: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 10,
    opacity: 0,
  },
};

const Wrapper = styled(motion.div)`
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;

  .container {
    background: ${dirtyWhite};
    border-radius: 13px;
    color: ${mainBlack};
    max-width: 1020px;
    width: 100%;
    padding: 32px 32px 48px 32px;
    position: relative;
    margin: 0 16px;
    box-shadow: 0 5px 8px -2px rgba(0, 0, 0, 0.1);
    max-height: calc(100vh - 154px);
    overflow-y: ${(props) => (props.isLoading ? "hidden" : "scroll")};
    @media ${screen.xxsmall} {
      max-height: calc(100vh - 80px);
      padding: 50px;
      margin: 0 50px;
    }

    .heading {
      font-weight: 900;
      line-height: 1.22;
      position: relative;
      font-size: 1.56rem;
      position: relative;
      max-width: 170px;
      margin: 0 0 46px 0;
      @media ${screen.xxsmall} {
        font-size: 3rem;
        margin: 0;
        max-width: 530px;
      }
      @media ${screen.small} {
        font-size: 3rem;
        line-height: 1.19;
      }

      .pointer {
        position: absolute;
        top: 40px;
        right: 10px;
        @media ${screen.xxsmall} {
          display: none;
        }
        @media ${screen.xsmall} {
          display: block;
          top: -35px;
          right: -20px;
        }

        &__arrow {
          svg {
            width: 42px;
            transform: rotate(25deg) scaleY(-1);
            @media ${screen.xxsmall} {
              width: 55px;
              transform: rotate(-10deg);
            }
          }
        }

        &__text {
          font-family: ${subFont};
          font-size: 1.16rem;
          font-weight: 400;
          line-height: 1.15;
          position: absolute;
          width: 100px;
          bottom: -5px;
          right: -109px;
          @media ${screen.xxsmall} {
            bottom: -21px;
            right: -113px;
            font-size: 1.4rem;
          }
        }
      }
    }

    .subheading {
      font-weight: 700;
      line-height: 1.4;
      margin: 36px 0 21px 0;
      font-size: 1.25rem;
      @media ${screen.xxsmall} {
        font-size: 1.52rem;
      }
      @media ${screen.small} {
        font-size: 1.55rem;
      }
    }

    .description {
      font-weight: 900;
      font-size: 1.06rem;
      margin: 16px 0 0 0;
      @media ${screen.small} {
        font-size: 1.1rem;
      }

      a {
        color: ${mainCyan};
        text-decoration: underline;
        @media ${screen.withCursor} {
          &:hover {
            color: ${lightPurple};
          }
        }
      }
    }

    .Dropdown-root {
      position: relative;
      margin: 0 0 12px 0;

      .Dropdown-control {
        border: 0;
        padding: 0;
        background: ${dirtyWhite};
        border-bottom: 2px ${mainCyan} solid;

        .Dropdown-placeholder {
          color: ${mainBlack};
          opacity: 0.5;
          line-height: 2;
          font-size: 1.06rem;
          cursor: pointer;
          @media ${screen.xxsmall} {
            font-size: 1.08rem;
          }
          @media ${screen.small} {
            font-size: 1.1rem;
          }
        }

        .is-selected {
          opacity: 1;
        }

        .Dropdown-arrow-wrapper {
          .icon-arrow {
            width: 17px;
            display: block;
            position: absolute;
            right: 0;
            top: 5px;

            .sharp-arrow {
              fill: ${mainCyan};
            }
          }
        }
      }

      .Dropdown-menu {
        max-height: 190px;
      }
    }

    .invalid-select {
      .Dropdown-control {
        border-bottom: 2px ${mainOrange} solid;

        .Dropdown-placeholder {
          color: ${mainOrange};
        }

        .Dropdown-arrow-wrapper {
          .icon-arrow {
            .sharp-arrow {
              fill: ${mainOrange};
            }
          }
        }
      }
    }

    .add-btn {
      letter-spacing: 2px;
      position: relative;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 1.06rem;
      color: ${mainCyan};
      cursor: pointer;
      display: flex;
      align-items: center;
      max-width: max-content;
      transition: ${transHover};
      @media ${screen.xxsmall} {
        font-size: 1.08rem;
      }
      @media ${screen.small} {
        font-size: 1.1rem;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${lightPurple};
        }
      }

      .icon-add {
        font-size: 2rem;
        margin: -1px 6px 0 0;
        display: block;
        font-weight: 400;
        @media ${screen.xxsmall} {
          font-size: 2.05rem;
        }
        @media ${screen.small} {
          font-size: 2.2rem;
        }
      }

      .label {
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      @media ${screen.xxsmall} {
        margin: 0 0 18px 0;
        flex-direction: row;
      }

      &__col {
        width: 100%;
        @media ${screen.xxsmall} {
          width: 50%;
        }

        &--right {
          @media ${screen.xxsmall} {
            margin: 0 0 0 70px;
          }
        }
      }

      input {
        background: none;
        color: ${mainBlack};
        width: 100%;
        border: 0;
        border-bottom: 2px ${mainCyan} solid;
        font-weight: 600;
        outline: none;
        border-radius: 0;
        line-height: 2;
        font-size: 16px;
        margin: 0 0 22px 0;
        padding: 0;
        @media ${screen.xxsmall} {
          font-size: 1.06rem;
          margin: 0;
        }
        @media ${screen.small} {
          font-size: 1.1rem;
        }

        &::placeholder {
          color: ${mainBlack};
          opacity: 0.5;
          font-weight: 400;
        }
      }

      &--full-width {
        display: block;
        margin: 0 0 6px 0;
        @media ${screen.xxsmall} {
          margin: 36px 0 36px 0;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          margin: 0 0 18px 0;
          @media ${screen.xxsmall} {
            font-size: 1.08rem;
          }
          @media ${screen.small} {
            font-size: 1.1rem;
          }
        }
      }

      &--submit-btn {
        display: flex;
        margin: 36px 0 0 0;
        flex-direction: column;
        @media ${screen.xxsmall} {
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
          margin: 21px 0 0 0;
        }

        p {
          letter-spacing: 1px;
          font-weight: 900;
          text-transform: uppercase;
          font-size: 0.8rem;
          margin: 0 0 26px 0;
          @media ${screen.xxsmall} {
            font-size: 0.8rem;
            margin: 0;
          }
          @media ${screen.small} {
            font-size: 0.85rem;
          }

          a {
            color: ${mainCyan};
            @media ${screen.withCursor} {
              &:hover {
                color: ${lightPurple};
              }
            }
          }
        }
      }

      &--checkbox {
        .row__col {
          position: relative;
          @media ${screen.xxsmall} {
            width: calc(50% - 35px);
          }

          input {
            pointer-events: none;
          }

          .box {
            width: 23px;
            height: 23px;
            position: absolute;
            right: 0;
            bottom: 29px;
            border: 2px ${mainCyan} solid;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            @media ${screen.xxsmall} {
              width: 30px;
              height: 30px;
              bottom: 4px;
            }

            svg {
              font-size: 1.5rem;
              color: ${mainBlack};
              opacity: 0.7;
            }
          }
        }
      }

      .invalid-text {
        border-bottom: 2px ${mainOrange} solid;
        color: ${mainOrange};

        &::placeholder {
          color: ${mainOrange};
        }
      }
    }

    .icon-close {
      position: absolute;
      top: 32px;
      z-index: 5;
      background: none;
      cursor: pointer;
      right: 32px;

      span {
        display: block;
      }

      .close-inner {
        fill: ${mainBlack};
      }

      svg {
        width: 18px;
        height: 18px;
        @media ${screen.xxsmall} {
          width: 28px;
          height: 28px;
        }
      }
    }

    #program-enquiry-spinner {
      position: fixed;
      background: rgba(255, 255, 255, 0.975);
      border-radius: 13px;
      width: calc(100% - 32px);
      height: 100%;
      max-width: 1020px;
      max-height: calc(100vh - 80px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 4;
      @media ${screen.xxsmall} {
        width: 100%;
      }

      .icon {
        svg {
          width: 160px;
          height: 160px;
          @media ${screen.xxsmall} {
            width: 220px;
            height: 220px;
          }
        }
      }

      .msg {
        font-size: 1.16rem;
        text-align: center;
        color: ${mainBlack};
        @media ${screen.xxsmall} {
          font-size: 2rem;
        }
        @media ${screen.xsmall} {
          font-size: 2.36rem;
        }

        .icon {
          display: block;
          margin: 0 0 2px 0;
          @media ${screen.xxsmall} {
            margin: 0 0 7px 0;
          }

          svg {
            width: 30px;
            height: 30px;
            @media ${screen.xxsmall} {
              width: 66px;
              height: 66px;
            }

            .e6d6beab-9455-4e6c-aa7a-7c7ada802c8f {
              fill: ${mainCyan};
            }
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${mainCyan};
    }

    .dog-details-list {
      display: flex;
      flex-direction: column-reverse;
    }
  }
`;

// dropdown program option
const programOptions = [
  "Canine Underwater Treadmill",
  "Indoor Heated Pool",
  "Jet Spa",
  "Canine Weight Loss Program",
  "Training and Conditioning Program",
  "Seniors Program",
  "Swimming Lessons",
  "Fun Swims",
  "Pool Hire for Groups",
  "Puppy Splash Program",
];

// dropdown gender option
const genderOptions = ["Male", "Female"];

const ProgramEnquiryForm = ({ isProgramEnquiry, setIsProgramEnquiry }) => {
  const data = useStaticQuery(dataQuery);
  const [ownerInput, setOwnerInput] = useState({});
  const [serviceLength, setServiceLength] = useState(["i"]);
  const [dogDetails, setDogDetails] = useState([
    {
      dog_name: "",
      dog_breed: "",
      dog_date_of_birth: "",
      dog_weight: "",
      dog_date_of_last_vaccination: "",
      dog_gender: "",
      dog_is_desexed: "No",
      dog_health_issues: "",
    },
  ]);
  const [selectedPrograms, setSelectedPrograms] = useState({});

  // trigger input validation
  const [validate, setValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  // form container for scrolling back up
  const formContainer = useRef(null);
  const downloadFormUrl = data.content.data.vet_referral_form.url;

  // ********START SERVICES DROPDOWN

  // returned only array of selected program as a string only
  const allSelectedPrograms = Object.values(selectedPrograms).map(
    (item) => item.value
  );

  // select program handler
  const handleSelectChange = (val, key) => {
    // if program already been trigger just update its value
    if (selectedPrograms[key]) {
      selectedPrograms[key].value = val;

      setSelectedPrograms({
        [key]: (selectedPrograms[key].value = val),
        ...selectedPrograms,
      });
    }
    // else add a new one
    else {
      const obj = {
        [key]: {
          value: val,
        },
      };

      setSelectedPrograms({ ...obj, ...selectedPrograms });
    }
  };

  // returned array with removed value that's been selected already
  const filteredOptions = programOptions.filter(
    (item) => !allSelectedPrograms.some((x) => x === item && x !== "—")
  );

  const handleAddService = () => {
    setValidate(false);
    // if options are not empty go add
    if (programOptions.length > serviceLength.length + 1) {
      setServiceLength(["i", ...serviceLength]);
    }
    // else, stop adding more service
    else {
      return false;
    }
  };
  // ********END SERVICES DROPDOWN

  // ********START DOG DROPDOWN

  // add another dog fields
  const handleAddDog = () => {
    setValidate(false);
    setDogDetails([
      {
        dog_name: "",
        dog_breed: "",
        dog_date_of_birth: "",
        dog_weight: "",
        dog_date_of_last_vaccination: "",
        dog_gender: "",
        dog_is_desexed: "No",
        dog_health_issues: "",
      },
      ...dogDetails,
    ]);
  };

  // dog text field on change handler
  const handleDogInput = (e, key) => {
    const updated = dogDetails[key];

    updated[e.target.name] = e.target.value;

    dogDetails[key] = updated;

    setDogDetails([...dogDetails]);
  };

  // dog checkbox handler
  const handleDogCheckbox = (key) => {
    // key passed from map array
    const updated = dogDetails[key];
    updated.dog_is_desexed = updated.dog_is_desexed === "No" ? "Yes" : "No";
    dogDetails[key] = updated;
    setDogDetails([...dogDetails]);
  };

  const handleDogGender = (val, key) => {
    const updated = dogDetails[key];
    updated.dog_gender = val;
    dogDetails[key] = updated;
    setDogDetails([...dogDetails]);
  };

  // ********END DOG DROPDOWN

  // ********START OWNER INPUT

  // owner input on change handler
  const handleOwnerInput = (e) => {
    if (e.target.name === "email") {
      ownerInput[e.target.name] = e.target.value.toLowerCase();
      setOwnerInput({
        ...ownerInput,
      });
    } else {
      ownerInput[e.target.name] = e.target.value;
      setOwnerInput({
        ...ownerInput,
      });
    }
  };

  // ********END OWNER INPUT

  // ********START SUBMISSION

  const handleSubmit = (e) => {
    e.preventDefault();

    // returned array of objects with keyed number
    const flatDogDetails = dogDetails.map((item, i) => {
      return {
        [`${i + 1}_dog_name`]: item.dog_name,
        [`${i + 1}_dog_breed`]: item.dog_breed,
        [`${i + 1}_dog_date_of_birth`]: item.dog_date_of_birth,
        [`${i + 1}_dog_weight`]: item.dog_weight,
        [`${
          i + 1
        }_dog_date_of_last_vaccination`]: item.dog_date_of_last_vaccination,
        [`${i + 1}_dog_gender`]: item.dog_gender,
        [`${i + 1}_dog_is_desexed`]: item.dog_is_desexed,
        [`${i + 1}_dog_health_issues`]: item.dog_health_issues,
      };
    });

    // returned object with dog keyed property
    const flattenDogDetails = Object.assign(...flatDogDetails);

    // return true if these properties are not empty
    const isAllDogDetailsFilled = dogDetails.every((item) => {
      return (
        item.dog_name &&
        item.dog_breed &&
        item.dog_date_of_birth &&
        item.dog_date_of_last_vaccination &&
        item.dog_gender &&
        item.dog_weight
      );
    });

    // if all true send the form!
    if (
      isAllDogDetailsFilled &&
      allSelectedPrograms.length !== 0 &&
      ownerInput["owner_first_name"] &&
      ownerInput["owner_last_name"] &&
      emailRegex.test(ownerInput["email"])
    ) {
      setIsLoading(true);
      axios({
        method: "post",
        url: "https://getform.io/f/12478763-058b-4e48-8aab-029131fd9186",
        data: encode({
          program: allSelectedPrograms,
          ...ownerInput,
          ...flattenDogDetails,
          html_template: getHtmlEmailTemplate(
            allSelectedPrograms,
            ownerInput,
            dogDetails
          ),
        }),
      })
        .then(function (response) {
          setIsSuccess(true);
          setOwnerInput({});
          setServiceLength(["i"]);
          setDogDetails([
            {
              dog_name: "",
              dog_breed: "",
              dog_date_of_birth: "",
              dog_weight: "",
              dog_date_of_last_vaccination: "",
              dog_gender: "",
              dog_is_desexed: "No",
              dog_health_issues: "",
            },
          ]);
          setSelectedPrograms({});
        })
        .catch(function (error) {
          setIsLoading(false);
          alert("Sorry, something went wrong. Please try again later!");
          setOwnerInput({});
          setServiceLength(["i"]);
          setDogDetails([
            {
              dog_name: "",
              dog_breed: "",
              dog_date_of_birth: "",
              dog_weight: "",
              dog_date_of_last_vaccination: "",
              dog_gender: "",
              dog_is_desexed: "No",
              dog_health_issues: "",
            },
          ]);
          setSelectedPrograms({});
        });
    }
    // else trigger the client side validation
    else {
      formContainer.current.scrollTo(0, 0);
      setValidate(true);
    }
  };
  // ********END SUBMISSION

  const closeForm = () => {
    setIsProgramEnquiry(false);
    setIsLoading(false);
    setIsSuccess(false);
    setOwnerInput({});
    setServiceLength(["i"]);
    setDogDetails([
      {
        dog_name: "",
        dog_breed: "",
        dog_date_of_birth: "",
        dog_weight: "",
        dog_date_of_last_vaccination: "",
        dog_gender: "",
        dog_is_desexed: "No",
        dog_health_issues: "",
      },
    ]);
    setSelectedPrograms({});
    setValidate(false);
  };

  // remove invalid style when form is focused
  const handleFocus = () => {
    setValidate(false);
  };

  // handle owner input text validation
  // returned a string to use for className property
  const handleOwnerTextValidation = (property) => {
    if (validate) {
      if (property === "email") {
        if (emailRegex.test(ownerInput["email"])) {
          return "";
        } else {
          return "invalid-text";
        }
      } else {
        if (ownerInput[property] && ownerInput[property].length) {
          return "";
        } else {
          return "invalid-text";
        }
      }
    }
  };

  // handle program dropdown validation
  // returned a string to use for className property
  const handleSelectValidation = (property) => {
    if (validate) {
      if (selectedPrograms[property] && selectedPrograms[property].value) {
        return "";
      } else {
        return "invalid-select";
      }
    }
  };

  // handle dog input text validation
  // returned a string to use for className property
  const handleDogTextValidation = (property, key) => {
    if (validate) {
      if (dogDetails[key][property] && dogDetails[key][property].length) {
        return "";
      } else {
        return "invalid-text";
      }
    }
  };

  // handle gender dropdown validation
  // returned a string to use for className property
  const handleDogSelectValidation = (property, key) => {
    if (validate) {
      if (dogDetails[key][property] && dogDetails[key][property].length) {
        return "";
      } else {
        return "invalid-select";
      }
    }
  };

  return (
    <ScrollLocky isolation={false} enabled={isProgramEnquiry}>
      <AnimatePresence>
        {isProgramEnquiry && (
          <Wrapper
            initial="hidden"
            animate={isProgramEnquiry ? "show" : "hidden"}
            variants={container}
            isLoading={isLoading}
            exit={{ opacity: 0 }}
          >
            <div className="container" ref={formContainer}>
              <form onSubmit={handleSubmit} onFocus={handleFocus}>
                <h5 className="heading">
                  Enquire with K9 SWiM
                  <span className="pointer" role="presentation">
                    <span className="pointer__arrow">
                      <IconArrowSquiggly />
                    </span>
                    <span className="pointer__text">let’s get started</span>
                  </span>
                </h5>
                <p className="description">
                  Complete form below or{" "}
                  <a
                    href={downloadFormUrl}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Download VET referral form"
                  >
                    download as a pdf
                  </a>
                </p>

                <motion.div variants={item}>
                  <h6 className="subheading">
                    Select programs you’re interested in:
                  </h6>

                  {serviceLength.map((item, i) => (
                    <Dropdown
                      key={item + i}
                      options={filteredOptions}
                      onChange={(e) => {
                        handleSelectChange(e.value, i);
                      }}
                      onFocus={handleFocus}
                      className={handleSelectValidation(`${i}`)}
                      placeholder="Select one program below"
                      arrowClosed={
                        <span className="icon-arrow">
                          <IconArrowSharpDown />
                        </span>
                      }
                      arrowOpen={
                        <span className="icon-arrow">
                          <IconArrowSharpDown />
                        </span>
                      }
                    />
                  ))}

                  <div
                    className="add-btn"
                    role="button"
                    onClick={handleAddService}
                  >
                    <span className="icon-add">+</span>
                    <span className="label">ADD ANOTHER SERVICE</span>
                  </div>
                </motion.div>

                <motion.div variants={item}>
                  <h6 className="subheading">Owner details:</h6>

                  <div className="owner-details">
                    <div className="row">
                      <div className="row__col">
                        <input
                          type="text"
                          autoComplete="off"
                          placeholder="First Name*"
                          name="owner_first_name"
                          value={ownerInput.owner_first_name || ""}
                          onChange={handleOwnerInput}
                          className={handleOwnerTextValidation(
                            "owner_first_name"
                          )}
                        />
                      </div>
                      <div className="row__col row__col--right">
                        <input
                          type="text"
                          autoComplete="off"
                          placeholder="Last Name*"
                          name="owner_last_name"
                          value={ownerInput.owner_last_name || ""}
                          onChange={handleOwnerInput}
                          className={handleOwnerTextValidation(
                            "owner_last_name"
                          )}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="row__col">
                        <input
                          type="email"
                          autoComplete="off"
                          placeholder="Email Address*"
                          name="email"
                          value={ownerInput.email || ""}
                          onChange={handleOwnerInput}
                          className={handleOwnerTextValidation("email")}
                        />
                      </div>
                      <div className="row__col row__col--right">
                        <input
                          type="text"
                          autoComplete="off"
                          placeholder="Phone Number"
                          name="owner_phone_number"
                          value={ownerInput.owner_phone_number || ""}
                          onChange={handleOwnerInput}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="Address"
                        name="owner_address"
                        value={ownerInput.owner_address || ""}
                        onChange={handleOwnerInput}
                      />
                    </div>
                  </div>
                </motion.div>

                <motion.div variants={item}>
                  <div className="dog-details-list">
                    {dogDetails.map((item, i) => (
                      <div key={item + i} className="dog-details">
                        <h6 className="subheading">Dog details:</h6>
                        <div className="row">
                          <div className="row__col">
                            <input
                              type="text"
                              autoComplete="off"
                              placeholder="Name*"
                              name="dog_name"
                              value={dogDetails[i].dog_name}
                              onChange={(e) => {
                                handleDogInput(e, i);
                              }}
                              className={handleDogTextValidation("dog_name", i)}
                            />
                          </div>
                          <div className="row__col row__col--right">
                            <input
                              type="text"
                              autoComplete="off"
                              placeholder="Breed*"
                              name="dog_breed"
                              value={dogDetails[i].dog_breed}
                              onChange={(e) => {
                                handleDogInput(e, i);
                              }}
                              className={handleDogTextValidation(
                                "dog_breed",
                                i
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="row__col">
                            <input
                              type="text"
                              autoComplete="off"
                              placeholder="Date of Birth* (dd/mm/yyyy)"
                              name="dog_date_of_birth"
                              value={dogDetails[i].dog_date_of_birth}
                              onChange={(e) => {
                                handleDogInput(e, i);
                              }}
                              className={handleDogTextValidation(
                                "dog_date_of_birth",
                                i
                              )}
                            />
                          </div>
                          <div className="row__col row__col--right">
                            <input
                              type="text"
                              autoComplete="off"
                              placeholder="Weight*"
                              name="dog_weight"
                              value={dogDetails[i].dog_weight}
                              onChange={(e) => {
                                handleDogInput(e, i);
                              }}
                              className={handleDogTextValidation(
                                "dog_weight",
                                i
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="row__col">
                            <input
                              type="text"
                              autoComplete="off"
                              placeholder="Date of last vaccination* (dd/mm/yyyy)"
                              name="dog_date_of_last_vaccination"
                              value={dogDetails[i].dog_date_of_last_vaccination}
                              onChange={(e) => {
                                handleDogInput(e, i);
                              }}
                              className={handleDogTextValidation(
                                "dog_date_of_last_vaccination",
                                i
                              )}
                            />
                          </div>
                          <div className="row__col row__col--right">
                            <Dropdown
                              className={handleDogSelectValidation(
                                "dog_gender",
                                i
                              )}
                              options={genderOptions}
                              onChange={(e) => {
                                handleDogGender(e.value, i);
                              }}
                              value={
                                dogDetails[i].dog_gender
                                  ? dogDetails[i].dog_gender
                                  : null
                              }
                              placeholder="Gender*"
                              arrowClosed={
                                <span className="icon-arrow">
                                  <IconArrowSharpDown />
                                </span>
                              }
                              arrowOpen={
                                <span className="icon-arrow">
                                  <IconArrowSharpDown />
                                </span>
                              }
                            />
                          </div>
                        </div>
                        <div
                          className="row row--checkbox"
                          onClick={() => {
                            handleDogCheckbox(i);
                          }}
                        >
                          <div className="row__col">
                            <input
                              type="text"
                              placeholder="Is your dog desexed?"
                            />
                            <div className="box">
                              {dogDetails[i].dog_is_desexed !== "No" && (
                                <IoIosPaw />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row row--full-width">
                          <p>
                            Does your dog have any health issues, injuries or on
                            any medication?
                          </p>
                          <input
                            type="text"
                            autoComplete="off"
                            placeholder="If yes, please note here"
                            name="dog_health_issues"
                            value={dogDetails[i].dog_health_issues}
                            onChange={(e) => {
                              handleDogInput(e, i);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="add-btn" role="button" onClick={handleAddDog}>
                    <span className="icon-add">+</span>
                    <span className="label">ADD ANOTHER DOG</span>
                  </div>
                </motion.div>

                <motion.div variants={item}>
                  <div className="row row--submit-btn">
                    <p>
                      By clicking submit, you agree to K9 SWiM’s{" "}
                      <a
                        href="/terms-and-conditions/"
                        target="_blank"
                        aria-label="Terms and conditions page"
                      >
                        Terms and Conditions.
                      </a>
                    </p>
                    <ButtonFilled label="SUBMIT" />
                  </div>
                </motion.div>
              </form>

              {!isSuccess && (
                <div className="icon-close" role="button" onClick={closeForm}>
                  <motion.span whileTap={{ scale: 0.97 }}>
                    <IconClose />
                  </motion.span>
                </div>
              )}

              {isLoading && (
                <div id="program-enquiry-spinner">
                  {!isSuccess && (
                    <div className="icon">
                      <IconSpinner />
                    </div>
                  )}

                  {isSuccess && (
                    <p className="msg">
                      <span className="icon">
                        <IconCheck />
                      </span>
                      Thank you for your enquiry!
                    </p>
                  )}

                  {isSuccess && (
                    <div
                      className="icon-close"
                      role="button"
                      onClick={closeForm}
                    >
                      <motion.span whileTap={{ scale: 0.97 }}>
                        <IconClose />
                      </motion.span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Wrapper>
        )}
      </AnimatePresence>
    </ScrollLocky>
  );
};

export default ProgramEnquiryForm;

const dataQuery = graphql`
  {
    content: prismicMiscellaneous {
      data {
        vet_referral_form {
          url
        }
      }
    }
  }
`;
