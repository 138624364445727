import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import {
  mainWhite,
  siteWidth,
  innerWidth,
  mainBlack,
  screen,
} from "../variables";
import LogoBrand from "../../images/svg/k9-brand.svg";

const floating = keyframes`
0% {
  transform: translate(0, 0px);
}

50% {
  transform: translate(0, -3px);
}

100% {
  transform: translate(0, 3px);
}
`;

const Wrapper = styled.section`
  background: ${mainWhite};
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: ${siteWidth};
  height: 60px;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  transform: ${(props) => props.stickyState};
  box-shadow: ${(props) =>
    props.isBoxShadow ? `0 5px 2px -2px rgba(0, 0, 0, 0.1)` : `none`};
  z-index: 10;
  @media ${screen.xxsmall} {
    height: 60px;
  }
  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    position: relative;
    width: 100%;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    .brand-link {
      position: absolute;
      left: 32px;
      top: 50%;
      transform: translateY(-50%);
      @media ${screen.xxsmall} {
        left: 42px;
      }
      @media ${screen.medium} {
        left: 0;
      }

      @media ${screen.withCursor} {
        &:hover #dog-stroke {
          animation: ${floating} 2.5s linear infinite alternate;
        }
      }

      svg {
        max-width: 112px;
        width: 140px;
        overflow: visible;
        @media ${screen.xxsmall} {
          width: 140px;
        }
      }
    }

    .menu-btn {
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);
      @media ${screen.xxsmall} {
        right: 42px;
      }
      @media ${screen.medium} {
        right: 0;
      }

      span {
        color: ${mainBlack};
        font-weight: 900;
        font-size: 1.25rem;
        cursor: pointer;
        display: block;
        @media ${screen.xxsmall} {
          font-size: 1.27rem;
        }
      }
    }
  }
`;

const StickyNavbar = ({
  topScroll,
  setIsMenuOpen,
  isTopNotificationOpen,
  isHydrotherapyCentrePage,
}) => {
  const getStickyState = () => {
    if (isHydrotherapyCentrePage) {
      if (topScroll > 400) {
        return "translateY(0)";
      } else if (topScroll > 400) {
        return "translateY(0)";
      } else {
        return "translateY(-60px)";
      }
    } else {
      if (isTopNotificationOpen && topScroll > 400) {
        return "translateY(60px)";
      } else if (topScroll > 400) {
        return "translateY(0)";
      } else {
        return "translateY(-60px)";
      }
    }
  };

  return (
    <Wrapper
      isTopNotificationOpen={isTopNotificationOpen}
      stickyState={getStickyState()}
      isBoxShadow={topScroll > 300}
    >
      <div className="inner-wrapper">
        <Link className="brand-link" to="/" aria-label="Home page">
          <LogoBrand />
        </Link>
        <div
          role="button"
          aria-label="Open menu"
          className="menu-btn"
          onClick={() => {
            setIsMenuOpen(true);
          }}
        >
          <motion.span whileTap={{ scale: 0.9 }}>MENU</motion.span>
        </div>
      </div>
    </Wrapper>
  );
};

export default StickyNavbar;
