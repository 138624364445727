const getHtmlEmailTemplate = (programs, input, dogs) => {
  return `
  <table width="600" style="padding: 15px 30px 30px 30px; font-family: Verdana, sans-serif; color: #262626;">
    <tr>
      <td>
        <h3 style="color: #6B5ED8; font-size: 20px; font-weight: 700;">
          Program Enquiry
        </h3>
        <br />
      </td>
    </tr>
    <tr>
      <td>
        <h3
          style="font-size: 18px; font-weight: 600; margin: 0; text-decoration: underline;"
        >
          Program:
        </h3>
      </td>
    </tr>
    <tr>
      <td>
        <ul>
        ${programs
          .map(
            (item, i) =>
              `<li style="font-size: 15px; font-weight: 400; line-height: 2;">
              ${item}
            </li>`
          )
          .join("")}
        </ul>
        <br />
        <br />
      </td>
    </tr>
    <tr>
      <td>
        <h3
          style="font-size: 18px; font-weight: 600; margin: 0; text-decoration: underline;"
        >
          Owner Details:
        </h3>
      </td>
    </tr>
    <tr>
      <td>
        <p style="font-size: 15px; font-weight: 400;">
          <strong>First name:</strong> ${input.owner_first_name}
        </p>
        <p style="font-size: 15px; font-weight: 400;">
          <strong>Last name:</strong> ${input.owner_last_name}
        </p>
        <p style="font-size: 15px; font-weight: 400;">
          <strong>Email:</strong> ${input.email}
        </p>
        <p style="font-size: 15px; font-weight: 400;">
          <strong>Phone:</strong> ${input.owner_phone_number || ""}
        </p>
        <p style="font-size: 15px; font-weight: 400;">
          <strong>Address:</strong> ${input.owner_address || ""}
        </p>
        <br />
        <br />
      </td>
    </tr>
    <tr>
      <td>
        <h3
          style="font-size: 18px; font-weight: 600; margin: 0; text-decoration: underline;"
        >
          Dog Details:
        </h3>
      </td>
    </tr>
    ${dogs
      .map(
        (dog, i) =>
          `<tr>
        <td style="border-bottom: 1px rgba(0,0,0,0.25) solid;">
          <p style="font-size: 15px; font-weight: 400;">
            <strong>Name:</strong> ${dog.dog_name}
          </p>
          <p style="font-size: 15px; font-weight: 400;">
            <strong>Breed:</strong> ${dog.dog_breed}
          </p>
          <p style="font-size: 15px; font-weight: 400;">
            <strong>Date of birth:</strong> ${dog.dog_date_of_birth}
          </p>
          <p style="font-size: 15px; font-weight: 400;">
            <strong>Weight:</strong> ${dog.dog_weight}
          </p>
          <p style="font-size: 15px; font-weight: 400;">
            <strong>Date of last vaccination:</strong> 
            ${dog.dog_date_of_last_vaccination}
          </p>
          <p style="font-size: 15px; font-weight: 400;">
            <strong>Gender:</strong> ${dog.dog_gender}
          </p>
          <p style="font-size: 15px; font-weight: 400;">
            <strong>Is desexed:</strong> ${dog.dog_is_desexed}
          </p>
          <p style="font-size: 15px; font-weight: 400;">
            <strong>Health issues:</strong> ${dog.dog_health_issues}
          </p>
        </td>
      </tr>`
      )
      .join("")}
  </table>`;
};

export default getHtmlEmailTemplate;
