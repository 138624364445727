import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import {
  mainOrange,
  mainPurple,
  mainWhite,
  siteWidth,
  innerWidth,
  screen,
} from "../variables";
import IconClose from "../../images/svg/icon-close.svg";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import Obfuscate from "react-obfuscate";

// motion framer animation props value
const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const item = {
  show: {
    x: 0,
    opacity: 1,
  },
  hidden: {
    x: 75,
    opacity: 0,
  },
};

const Wrapper = styled(motion.section)`
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 50;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  transform: translateX(-50%);
  max-width: ${siteWidth};
  overflow: hidden;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    position: relative;
    @media ${screen.xxsmall} {
      padding: 0 0 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    .menu-wrapper {
      background: ${mainOrange};
      max-width: 1000px;
      width: 100%;
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: column;
      padding: 0 32px;
      @media ${screen.xxsmall} {
        max-width: 755px;
        padding: 0;
      }
      @media ${screen.xsmall} {
        padding: 0 48px;
        max-width: 880px;
      }
      @media ${screen.small} {
        padding: 0 48px;
        max-width: 1000px;
      }
      @media ${screen.large} {
        padding: 0;
      }

      .main-menu {
        display: flex;
        @media ${screen.xxsmall} {
          padding: 110px 0 0 75px;
        }
        @media ${screen.large} {
          padding: 120px 0 0 120px;
        }

        @media ${screen.xlarge} {
          padding: 120px 0 0 175px;
        }

        &__col {
          .heading {
            display: none;
            @media ${screen.xxsmall} {
              display: block;
              color: #ffd8d4;
              font-weight: 900;
              letter-spacing: 1px;
              font-size: 1.08rem;
            }
          }

          .menu-list {
            margin: 85px 0 0 0;
            @media ${screen.xxsmall} {
              margin: 34px 0 0 0;
            }

            &__each {
              margin: 0 0 20px 0;

              a {
                color: ${mainWhite};
                font-size: 1.7rem;
                font-weight: 900;
                @media ${screen.xxsmall} {
                  font-size: 1.75rem;
                }
                @media ${screen.small} {
                  font-size: 2.25rem;
                }
                @media ${screen.withCursor} {
                  &:hover {
                    color: ${mainPurple};
                  }
                }
              }

              &--services {
                .mobile-only {
                  position: relative;
                  @media ${screen.xxsmall} {
                    display: none;
                  }

                  .toggle-icon {
                    position: absolute;
                    top: 5px;
                    right: -26px;
                    font-size: 1.8rem;
                  }
                }

                .desktop-only {
                  display: none;
                  @media ${screen.xxsmall} {
                    display: block;
                  }
                }

                .submenu-list {
                  margin: 21px 0 0 0;
                  padding: 0 0 0 21px;
                  @media ${screen.xxsmall} {
                    display: none;
                  }
                  a {
                    display: block;
                    font-size: 1.4rem;
                    margin: 0 0 21px 0;
                  }
                }
              }
            }
          }

          &--left {
            margin: 0 102px 0 0;
            @media ${screen.large} {
              margin: 0 127px 0 0;
            }
          }

          &--right {
            display: none;
            @media ${screen.xxsmall} {
              display: block;
            }
          }
        }

        .active {
          color: ${mainPurple} !important;
          @media ${screen.withCursor} {
            pointer-events: none;
          }
        }
      }

      .footer-menu {
        margin: auto 0 32px 0;
        @media ${screen.xxsmall} {
          padding: 0 0 0 75px;
        }
        @media ${screen.small} {
          padding: 120px 0 0 75px;
          margin: auto 0 80px 0;
        }
        @media ${screen.large} {
          padding: 120px 0 0 120px;
        }

        @media ${screen.xlarge} {
          padding: 120px 0 0 175px;
          margin: auto 0 100px 0;
        }

        &__social-list {
          display: flex;
          li {
            a {
              font-size: 2rem;
              color: ${mainWhite};
              margin: 0 35px 0 0;
              @media ${screen.xxsmall} {
                margin: 0 55px 0 0;
              }
              @media ${screen.small} {
                font-size: 2.5rem;
              }
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainPurple};
                }
              }
            }
          }
        }

        &__details {
          display: none;
          @media ${screen.xxsmall} {
            display: flex;
            margin: 30px 0 0 0;
          }
          @media ${screen.xlarge} {
            margin: 21px 0 0 0;
          }

          .col {
            a {
              color: ${mainWhite};
              font-weight: 900;
              letter-spacing: 1px;
              font-size: 0.95rem;
              display: block;
              line-height: 1.58;
              @media ${screen.small} {
                font-size: 1.08rem;
              }
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainPurple};
                }
              }
            }

            &--left {
              margin: 0 102px 0 0;
              @media ${screen.large} {
                margin: 0 127px 0 0;
              }
            }
          }
        }
      }
    }

    &::after {
      content: "";
      background: ${mainOrange};
      position: absolute;
      z-index: 1;
      width: 50%;
      height: 100%;
      right: -25%;
    }

    .icon-close {
      position: absolute;
      top: 40px;
      right: 32px;
      z-index: 3;
      background: none;
      cursor: pointer;
      @media ${screen.xxsmall} {
        right: 42px;
        top: 58px;
      }
      @media ${screen.xsmall} {
        right: 48px;
      }
      @media ${screen.large} {
        right: 0;
      }

      span {
        display: block;
      }

      svg {
        width: 26px;
        height: 26px;
        @media ${screen.xxsmall} {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
`;

const OverlayMenu = ({
  isMenuOpen,
  setIsMenuOpen,
  isSubmenuOpen,
  setIsSubmenuOpen,
}) => {
  const toggleSubmenu = (e) => {
    e.preventDefault();
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const closeOverlayMenu = () => {
    setIsMenuOpen(false);
    setIsSubmenuOpen(false);
  };

  const pathName = typeof window !== "undefined" && window.location.pathname;

  // close menu if already on the page
  const isOnPage = (e) => {
    if (pathName === e.target.pathname) {
      closeOverlayMenu();
    }
  };

  if (typeof window !== "undefined") {
    enablePageScroll();
  }

  if (typeof window !== "undefined" && isMenuOpen) {
    disablePageScroll();
  }

  return (
    <AnimatePresence>
      {isMenuOpen && (
        <Wrapper
          initial="hidden"
          animate={isMenuOpen ? "show" : "hidden"}
          variants={container}
          exit={{ opacity: 0 }}
        >
          <div className="inner-wrapper">
            <div className="menu-wrapper">
              <div className="main-menu">
                <motion.div
                  variants={item}
                  className="main-menu__col main-menu__col--left"
                >
                  <h6 className="heading">K9 SWiM</h6>
                  <ul className="menu-list">
                    <li className="menu-list__each">
                      <Link
                        activeClassName="active"
                        to="/"
                        onClick={isOnPage}
                        aria-label="Home page"
                      >
                        Home
                      </Link>
                    </li>
                    <li className="menu-list__each">
                      <Link
                        activeClassName="active"
                        to="/about/"
                        onClick={isOnPage}
                        aria-label="About us page"
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="menu-list__each menu-list__each--services">
                      <Link
                        activeClassName="active"
                        className="desktop-only"
                        to="/services/"
                        onClick={isOnPage}
                        aria-label="Services page"
                      >
                        Services
                      </Link>
                      <Link
                        activeClassName="active"
                        className="mobile-only"
                        to="/services/"
                        onClick={toggleSubmenu}
                        aria-label="Services dropdown button"
                        role="button"
                      >
                        Services{" "}
                        {isSubmenuOpen ? (
                          <span className="toggle-icon">&minus;</span>
                        ) : (
                          <span className="toggle-icon">+</span>
                        )}
                      </Link>

                      {isSubmenuOpen && (
                        <div className="submenu-list">
                          <Link
                            activeClassName="active"
                            to="/services/hydrotherapy-centre/"
                            onClick={isOnPage}
                            aria-label="Hydrotherapy page"
                          >
                            Hydrotherapy
                          </Link>
                          <Link
                            activeClassName="active"
                            to="/services/wellness-centre/"
                            onClick={isOnPage}
                            aria-label="Wellness Centre page"
                          >
                            Wellness Centre
                          </Link>
                          <Link
                            activeClassName="active"
                            to="/services/short-courses/"
                            onClick={isOnPage}
                            aria-label="Short Courses page"
                          >
                            Short Courses
                          </Link>
                        </div>
                      )}
                    </li>
                    <li className="menu-list__each">
                      <Link
                        activeClassName="active"
                        to="/blog/"
                        onClick={isOnPage}
                        aria-label="News and Media page"
                      >
                        News & Media
                      </Link>
                    </li>
                    <li className="menu-list__each">
                      <Link
                        activeClassName="active"
                        to="/faqs/"
                        onClick={isOnPage}
                        aria-label="Frequently asked questions page"
                      >
                        FAQs
                      </Link>
                    </li>
                    <li className="menu-list__each">
                      <Link
                        activeClassName="active"
                        to="/contact/"
                        onClick={isOnPage}
                        aria-label="Contact page"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </motion.div>
                <motion.div
                  className="main-menu__col main-menu__col--right"
                  variants={item}
                >
                  <h6 className="heading">SERVICES</h6>
                  <ul className="menu-list">
                    <li className="menu-list__each">
                      <Link
                        activeClassName="active"
                        to="/services/hydrotherapy-centre/"
                        onClick={isOnPage}
                        aria-label="Hydrotherapy page"
                      >
                        Hydrotherapy
                      </Link>
                    </li>
                    <li className="menu-list__each">
                      <Link
                        activeClassName="active"
                        to="/services/wellness-centre/"
                        onClick={isOnPage}
                        aria-label="Wellness Centre page"
                      >
                        Wellness Centre
                      </Link>
                    </li>
                    <li className="menu-list__each">
                      <Link
                        activeClassName="active"
                        to="/services/short-courses/"
                        onClick={isOnPage}
                        aria-label="Short Courses page"
                      >
                        Short Courses
                      </Link>
                    </li>
                  </ul>
                </motion.div>
              </div>

              <div className="footer-menu">
                <motion.ul variants={item} className="footer-menu__social-list">
                  <li>
                    <a
                      href="https://www.facebook.com/CanineHydrotherapyCentre"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Facebook account"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/k9-swim"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="LinkedIn account"
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/k9_swim"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Instagram account"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                </motion.ul>

                <motion.div variants={item} className="footer-menu__details">
                  <div className="col col--left">
                    <a href="tel:1300787064" aria-label="Phone number">
                      1300 787 064
                    </a>
                    <Obfuscate
                      email={"info@k9swim.com.au"}
                      aria-label="Email address"
                    />
                  </div>
                  <div className="col">
                    <a
                      href="https://www.google.com/maps/place/853+Kurmond+Rd,+North+Richmond+NSW+2754/@-33.5526568,150.7519609,17z/data=!3m1!4b1!4m5!3m4!1s0x6b0d7cc677fdb14b:0x582327bf9ab9975a!8m2!3d-33.5526613!4d150.7541496"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Location address"
                    >
                      853 KURMOND ROAD,
                      <br /> NORTH RICHMOND, NSW AUSTRALIA 2754
                    </a>
                  </div>
                </motion.div>
              </div>
            </div>

            <div
              className="icon-close"
              role="button"
              onClick={closeOverlayMenu}
              aria-label="Close menu"
            >
              <motion.span whileTap={{ scale: 0.9 }}>
                <IconClose />
              </motion.span>
            </div>
          </div>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default OverlayMenu;
