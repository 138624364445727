import React from "react";
import styled, { css } from "styled-components";
import { subFont, mainBlack, screen } from "../variables";
import { motion } from "framer-motion";
import CircleCyanLg from "../../images/svg/circle-large-blue.svg";
import CircleCyanMd from "../../images/svg/circle-medium-blue.svg";
import CircleCyanSm from "../../images/svg/circle-small-blue.svg";
import CircleWhiteLg from "../../images/svg/circle-large-white.svg";
import CircleWhiteMd from "../../images/svg/circle-medium-white.svg";
import CircleWhiteSm from "../../images/svg/circle-small-white.svg";
import CircleLightPurpleLg from "../../images/svg/circle-large-lightpurple.svg";
import CircleLightPurpleMd from "../../images/svg/circle-medium-lightpurple.svg";
import CircleLightPurpleSm from "../../images/svg/circle-small-lightpurple.svg";
import CircleDarkPurpleLg from "../../images/svg/circle-large-darkpurple.svg";
import CircleDarkPurpleMd from "../../images/svg/circle-medium-darkpurple.svg";
import CircleDarkPurpleSm from "../../images/svg/circle-small-darkpurple.svg";
import IconArrowRightFullCurve from "../../images/svg/arrow-right-full-curve.svg";

const pointerStyle = css`
  .pointer {
    position: absolute;
    bottom: -20px;
    left: 0;
    display: none;
    @media ${screen.xxsmall} {
      display: block;
    }

    &__arrow {
      svg {
        width: 72px;
        transform: scaleX(-1) rotate(118deg);
      }
    }

    &__text {
      color: ${mainBlack};
      font-family: ${subFont};
      letter-spacing: 1px;
      font-size: 1.06rem;
      font-weight: 400;
      position: absolute;
      width: 200px;
      top: 30px;
      line-height: 1.15;
      left: 19px;
      text-align: center;
      @media ${screen.xxsmall} {
        font-size: 1.4rem;
        width: 200px;
      }

      .break {
        display: block;
      }
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;

  .circle {
    display: block;

    svg {
    }
  }

  /* pointer style */
  ${(props) => props.withPointer && pointerStyle}
`;

export const IconCircleDarkPurpleSm = ({ className }) => {
  return (
    <Wrapper className={className}>
      <motion.span className="circle">
        <CircleDarkPurpleSm />
      </motion.span>
    </Wrapper>
  );
};

export const IconCircleDarkPurpleMd = ({ className }) => {
  return (
    <Wrapper className={className}>
      <motion.span
        className="circle"
        animate={{ y: 9 }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1.9,
        }}
      >
        <CircleDarkPurpleMd />
      </motion.span>
    </Wrapper>
  );
};

export const IconCircleDarkPurpleLg = ({ className }) => {
  return (
    <Wrapper className={className}>
      <motion.span
        className="circle"
        animate={{ y: 9 }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1.9,
        }}
      >
        <CircleDarkPurpleLg />
      </motion.span>
    </Wrapper>
  );
};

export const IconCircleLightPurpleSm = ({ className }) => {
  return (
    <Wrapper className={className}>
      <motion.span
        className="circle"
        animate={{ y: 9 }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1.9,
        }}
      >
        <CircleLightPurpleSm />
      </motion.span>
    </Wrapper>
  );
};

export const IconCircleLightPurpleMd = ({ className, withPointer }) => {
  return (
    <Wrapper className={className} withPointer={withPointer}>
      <motion.span
        className="circle"
        animate={{ y: -9 }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1.9,
        }}
      >
        <CircleLightPurpleMd />
      </motion.span>
      {withPointer && (
        <span className="pointer" role="presentation">
          <span className="pointer__arrow">
            <IconArrowRightFullCurve />
          </span>
          <a
            href="https://www.instagram.com/k9_swim/"
            target="_blank"
            rel="noreferrer"
            className="pointer__text"
          >
            <span className="break">@k9_swim </span>follow us for more fluffy
            content!
          </a>
        </span>
      )}
    </Wrapper>
  );
};

export const IconCircleLightPurpleLg = ({ className }) => {
  return (
    <Wrapper className={className}>
      <motion.span
        className="circle"
        animate={{ y: 9 }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1.9,
        }}
      >
        <CircleLightPurpleLg />
      </motion.span>
    </Wrapper>
  );
};

export const IconCircleWhiteSm = ({ className }) => {
  return (
    <Wrapper className={className}>
      <motion.span
        className="circle"
        animate={{ y: 9 }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1.9,
        }}
      >
        <CircleWhiteSm />
      </motion.span>
    </Wrapper>
  );
};

export const IconCircleWhiteMd = ({ className }) => {
  return (
    <Wrapper className={className}>
      <motion.span
        className="circle"
        animate={{ y: 9 }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1.9,
        }}
      >
        <CircleWhiteMd />
      </motion.span>
    </Wrapper>
  );
};

export const IconCircleWhiteLg = ({ className }) => {
  return (
    <Wrapper className={className}>
      <motion.span
        className="circle"
        animate={{ y: 9 }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1.9,
        }}
      >
        <CircleWhiteLg />
      </motion.span>
    </Wrapper>
  );
};

export const IconCircleCyanSm = ({ className }) => {
  return (
    <Wrapper className={className}>
      <motion.span
        className="circle"
        animate={{ y: 9 }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1.9,
        }}
      >
        <CircleCyanSm />
      </motion.span>
    </Wrapper>
  );
};

export const IconCircleCyanMd = ({ className }) => {
  return (
    <Wrapper className={className}>
      <motion.span
        className="circle"
        animate={{ y: 9 }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1.9,
        }}
      >
        <CircleCyanMd />
      </motion.span>
    </Wrapper>
  );
};

export const IconCircleCyanLg = ({ className }) => {
  return (
    <Wrapper className={className}>
      <motion.span
        className="circle"
        animate={{ y: 9 }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1.9,
        }}
      >
        <CircleCyanLg />
      </motion.span>
    </Wrapper>
  );
};
