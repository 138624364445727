export const organization = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "K9 Swim",
  legalName: "K9 Swim",
  url: "https://www.k9swim.com.au",
  logo: "https://www.k9swim.com.au/k9-swim.png",
  foundingDate: "2013",
  founders: [
    {
      "@type": "Person",
      name: "Sharon Joanne Osmond",
    },
  ],
  address: {
    "@type": "PostalAddress",
    streetAddress: "Hydrotherapy Centre, 853 Kurmond Rd",
    addressLocality: "Hydrotherapy Centre, 853 Kurmond Rd",
    addressRegion: "North Richmond",
    postalCode: "2754",
    addressCountry: "AU",
  },
  contactPoint: {
    "@type": "ContactPoint",
    contactType: "customer support",
    telephone: "[1300 787 064]",
    email: "info@k9swim.com.au",
  },
  sameAs: [
    "https://www.facebook.com/CanineHydrotherapyCentre",
    "https://www.youtube.com/c/K9SwimLondonderry",
    "https://www.instagram.com/k9_swim",
    "https://www.linkedin.com/company/k9-swim",
  ],
};

export const getWebPageSchema = (name, url, description) => {
  return {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name,
    url,
    description,
    publisher: {
      "@type": "ProfilePage",
      name: "K9 Swim",
    },
  };
};

export const getArticleSchema = (
  headline,
  alternativeHeadline,
  image,
  wordcount,
  url,
  datePublished,
  description,
  articleBody
) => {
  return {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: headline,
    alternativeHeadline: alternativeHeadline,
    image: image ? image : "https://www.k9swim.com.au/k9-swim.png",
    author: "K9 Swim",
    genre: "Dog",
    keywords: "K9 Swim",
    wordcount: wordcount,
    publisher: {
      "@type": "Organization",
      name: "K9 Swim",
      logo: {
        "@type": "ImageObject",
        url: "https://www.k9swim.com.au/k9-swim.png",
      },
    },
    url,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": url,
    },
    datePublished: datePublished,
    dateCreated: datePublished,
    description,
    articleBody,
  };
};
