import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import {
  organization,
  getWebPageSchema,
  getArticleSchema,
} from "../structured-data";

function SEO({
  description,
  lang,
  isHomePage,
  webPageInfo,
  articleInfo,
  meta,
  title,
  image,
  url,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = "K9 Swim";
  const defaultImg = "https://www.k9swim.com.au/k9-swim.png";

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: "K9 SWiM - Hydrotherapy & Wellness Centre",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          url: url,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image ? image : defaultImg,
        },
        {
          property: `og:image:width`,
          content: "600",
        },
        {
          property: `og:image:height`,
          content: "600",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:color`,
          content: `#23C7E5`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {isHomePage && (
        <script type="application/ld+json">
          {JSON.stringify(organization)}
        </script>
      )}

      {webPageInfo && (
        <script type="application/ld+json">
          {JSON.stringify(
            getWebPageSchema(
              webPageInfo.name,
              webPageInfo.url,
              webPageInfo.description
            )
          )}
        </script>
      )}

      {articleInfo && (
        <script type="application/ld+json">
          {JSON.stringify(
            getArticleSchema(
              articleInfo.headline,
              articleInfo.alternativeHeadline,
              articleInfo.image,
              articleInfo.wordcount,
              articleInfo.url,
              articleInfo.datePublished,
              articleInfo.description,
              articleInfo.articleBody
            )
          )}
        </script>
      )}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
