import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Waypoint } from "react-waypoint";
import "../css/global.css";
import {
  mainFont,
  mainWhite,
  mainBlack,
  siteWidth,
  screen,
} from "../components/variables";
import Nav from "../components/nav";
import Footer from "../components/footer";
import ProgramEnquiryForm from "../components/program-enquiry-form";
import NewsletterForm from "./newsletter-form/popup";
import EnquiryWidget from "../components/enquiry-widget";
import useScrollPosition from "@react-hook/window-scroll";

const Wrapper = styled.div`
  background: ${mainWhite};
  color: ${mainBlack};
  font-family: ${mainFont};
  max-width: ${siteWidth};
  margin: 0 auto;
  position: relative;
  padding: 110px 0 0 0;
  overflow: ${(props) => (props.isHomePage ? "hidden" : "visible")};
  @media ${screen.xxsmall} {
    padding: 200px 0 0 0;
  }
`;

const Layout = ({
  children,
  isHomePage,
  isHydrotherapyCentrePage,
  is404Page,
  isBlogPage,
  hideFooter,
  noCirclesOnMobileFooter,
}) => {
  const scrollY = useScrollPosition(60);

  // is scrolled on footer
  const [isNotOnFooter, setIsNotOnFooter] = useState(true);

  // current browser width checker
  const [browserWidth, setBrowserWidth] = useState(
    typeof window !== "undefined" && window.innerWidth
  );

  // current browser height checker
  const browserHeight = typeof window !== "undefined" && window.innerHeight;

  const [isProgramEnquiry, setIsProgramEnquiry] = useState(false);
  const [isTopNotificationOpen, setIsTopNotificationOpen] = useState(false);

  useEffect(() => {
    const reportWindowSize = () => {
      setBrowserWidth(window.innerWidth);
    };
    window.addEventListener("resize", reportWindowSize);
    const el = document.getElementById("home-dog-artwork");

    if (window.sessionStorage.getItem("downloadreferralform")) {
      setIsTopNotificationOpen(false);
    } else {
      setIsTopNotificationOpen(true);
    }

    //  notification bar(vet fee form link) position based on browser width
    if (el && browserWidth < 599) {
      if (isTopNotificationOpen) {
        el.style.top = "172px";
      } else {
        el.style.top = "118px";
      }
    } else {
      if (el) {
        el.style.top = "";
      }
    }

    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, [isTopNotificationOpen, browserWidth]);

  return (
    <Wrapper
      isTopNotificationOpen={isTopNotificationOpen}
      isHomePage={isHomePage}
      id="k9-site-container"
    >
      <Nav
        isHomePage={isHomePage}
        isTopNotificationOpen={isTopNotificationOpen}
        isHydrotherapyCentrePage={isHydrotherapyCentrePage}
        setIsTopNotificationOpen={setIsTopNotificationOpen}
      />
      <main className="pages">{children}</main>
      {!is404Page && (
        <Waypoint
          onEnter={() => {
            setIsNotOnFooter(false);
          }}
          onLeave={() => {
            setIsNotOnFooter(true);
          }}
        >
          <footer>
            <Footer
              hideFooter={hideFooter}
              noCirclesOnMobileFooter={noCirclesOnMobileFooter}
            />
          </footer>
        </Waypoint>
      )}

      <NewsletterForm isBlogPage={isBlogPage} />

      <EnquiryWidget
        isChatWidget={isNotOnFooter && scrollY > browserHeight - 200}
        setIsProgramEnquiry={setIsProgramEnquiry}
      />

      <ProgramEnquiryForm
        isProgramEnquiry={isProgramEnquiry}
        setIsProgramEnquiry={setIsProgramEnquiry}
      />
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
