import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { mainWhite, mainBlack, innerWidth, screen } from "../variables";
import TopNotification from "./top-notification";
import OverlayMenu from "./overlay-menu";
import StickyNavbar from "./sticky-navbar";
import LogoBrand from "../../images/svg/k9-brand.svg";
import useScrollPosition from "@react-hook/window-scroll";

const floating = keyframes`
0% {
  transform: translate(0, 0px);
}

50% {
  transform: translate(0, -3px);
}

100% {
  transform: translate(0, 3px);
}
`;

const Wrapper = styled.nav`
  #hidden-bar {
    height: ${(props) =>
      props.isTopNotificationOpen && !props.isHydrotherapyCentrePage
        ? "60px"
        : 0};
    transition: height 0.2s ease-in-out;
    visibility: hidden;
    @media ${screen.xxsmall} {
      height: ${(props) =>
        props.isTopNotificationOpen && !props.isHydrotherapyCentrePage
          ? "30px"
          : 0};
    }
  }

  #navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: ${innerWidth};
    margin: ${(props) =>
      props.isHydrotherapyCentrePage || !props.isTopNotificationOpen
        ? "-70px auto 0 auto"
        : "0 auto"};
    padding: 0 32px;
    transition: margin 0.2s ease-in-out;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2;
    top: 100px;
    overflow: visible;
    @media ${screen.xsmall} {
      padding: 0 48px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    .brand {
      width: 100%;
      max-width: 130px;
      @media ${screen.xxsmall} {
        max-width: 187px;
      }
      @media ${screen.small} {
        max-width: 227px;
      }

      svg {
        overflow: visible;
      }

      @media ${screen.withCursor} {
        &:hover #dog-stroke {
          animation: ${floating} 2.5s linear infinite alternate;
        }
      }
    }

    .menu-btn {
      span {
        color: ${mainBlack};
        font-weight: 900;
        font-size: 1.25rem;
        cursor: pointer;
        display: block;
        @media ${screen.xxsmall} {
          font-size: 1.69rem;
          color: ${(props) => (props.isHomePage ? mainWhite : mainBlack)};
        }
        @media ${screen.small} {
          font-size: 1.78rem;
        }
      }
    }
  }
`;

const Nav = ({
  isHomePage,
  isHydrotherapyCentrePage,
  isTopNotificationOpen,
  setIsTopNotificationOpen,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const topScroll = useScrollPosition(80);

  useEffect(() => {
    const userClick = (e) => {
      if (e.target.className === "inner-wrapper") {
        setIsMenuOpen(false);
      }
    };

    const userKeydown = (e) => {
      if (e.key === "Escape") {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("click", userClick);
    window.addEventListener("keydown", userKeydown);

    return () => {
      window.removeEventListener("click", userClick);
      window.removeEventListener("keydown", userClick);
    };
  }, []);

  return (
    <>
      {!isHydrotherapyCentrePage && (
        <TopNotification
          isTopNotificationOpen={isTopNotificationOpen}
          setIsTopNotificationOpen={setIsTopNotificationOpen}
        />
      )}

      <StickyNavbar
        setIsMenuOpen={setIsMenuOpen}
        isTopNotificationOpen={isTopNotificationOpen}
        isHydrotherapyCentrePage={isHydrotherapyCentrePage}
        topScroll={topScroll}
      />
      <Wrapper
        isHomePage={isHomePage}
        isTopNotificationOpen={isTopNotificationOpen}
        isHydrotherapyCentrePage={isHydrotherapyCentrePage}
      >
        <div id="hidden-bar">hidden</div>

        <section id="navbar">
          <Link to="/" className="brand" aria-label="Home page">
            <LogoBrand />
          </Link>

          <div
            role="button"
            aria-label="Open menu"
            className="menu-btn"
            onClick={() => {
              setIsMenuOpen(true);
            }}
          >
            <motion.span whileTap={{ scale: 0.9 }}>MENU</motion.span>
          </div>
        </section>
      </Wrapper>
      <OverlayMenu
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        isSubmenuOpen={isSubmenuOpen}
        setIsSubmenuOpen={setIsSubmenuOpen}
      />
    </>
  );
};

export default Nav;
