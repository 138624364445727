import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import {
  subFont,
  mainWhite,
  mainOrange,
  screen,
} from "../../components/variables";

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 30;
  height: 94px;
  bottom: 32px;
  right: 32px;
  overflow: hidden;
  border-radius: 0 0 30px 30px;
  filter: drop-shadow(3px 10px 6px rgba(0, 0, 0, 0.28));
  width: 227px;
  @media ${screen.xxsmall} {
    border-radius: 0 0 44px 44px;
    width: 210px;
    height: 132px;
  }
  @media ${screen.small} {
    width: 227px;
    border-radius: 0 0 50px 50px;
    height: 138px;
  }

  .label {
    display: block;
    cursor: pointer;
    color: ${mainWhite};
    font-family: ${subFont};
    letter-spacing: 1px;
    background: ${mainOrange};
    text-align: center;
    height: 58px;
    border-radius: 30px;
    padding: 10px 22px;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1.23rem;
    width: 227px;
    display: none;
    @media ${screen.xxsmall} {
      border-radius: 44px;
      display: block;
      font-size: 1.4rem;
      width: 210px;
      height: 80px;
    }
    @media ${screen.small} {
      height: 85px;
      border-radius: 50px;
      font-size: 1.6rem;
      width: 227px;
      padding: 15px 25px;
    }

    span {
      position: absolute;
      left: 31px;
      top: 50%;
      transform: translateY(-50%);
      @media ${screen.xxsmall} {
        left: 25px;
      }
    }

    &--mobile-only {
      display: block;
      @media ${screen.xxsmall} {
        display: none;
      }
    }
  }

  .img {
    position: absolute;
    cursor: pointer;
    width: 70px;
    bottom: 0;
    right: 0;
    @media ${screen.xxsmall} {
      width: 100px;
    }
    @media ${screen.small} {
      width: 104px;
    }
  }
`;

const EnquiryWidget = ({ isChatWidget, setIsProgramEnquiry }) => {
  return (
    <Wrapper
      role="button"
      initial={{ opacity: 0, y: 25, pointerEvents: "none" }}
      animate={{
        opacity: isChatWidget ? 1 : 0,
        y: isChatWidget ? 0 : 25,
        pointerEvents: isChatWidget ? "all" : "none",
      }}
      whileTap={{ scale: 0.98 }}
      onClick={() => {
        setIsProgramEnquiry(true);
      }}
    >
      <div className="label">
        <span>
          ENQUIRE
          <br />
          NOW
        </span>
      </div>

      <div className="label label--mobile-only">
        <span>ENQUIRE NOW</span>
      </div>

      <div className="img">
        <StaticImage
          src="../../images/k9swim-enquiry.png"
          alt="K9 SWiM Enquire Now"
        />
      </div>
    </Wrapper>
  );
};

export default EnquiryWidget;
