import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { ScrollLocky } from "react-scroll-locky";
import { StaticImage } from "gatsby-plugin-image";
import { motion, AnimatePresence } from "framer-motion";
import {
  mainPurple,
  mainBlack,
  mainCyan,
  mainWhite,
  screen,
} from "../variables";
import addToMailchimp from "gatsby-plugin-mailchimp";
import ButtonFilled from "../buttons/button-filled";
import IconClose from "../../images/svg/icon-close.svg";
import IconSpinner from "../../images/svg/icon-loading.svg";

// motion framer animation props value
const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const item = {
  show: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 75,
    opacity: 0,
  },
};

const Wrapper = styled(motion.div)`
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    background: ${mainPurple};
    border-radius: 13px;
    color: ${mainWhite};
    max-width: 1250px;
    width: 100%;
    padding: 42px 32px 140px 32px;
    position: relative;
    margin: 0 16px;
    @media ${screen.xxsmall} {
      padding: 50px;
      margin: 0 50px;
    }

    .flex {
      display: flex;
      .col {
        &--form {
          @media ${screen.xxsmall} {
            width: 65%;
            padding: 0 50px 0 0;
          }
          @media ${screen.xsmall} {
            width: 50%;
          }

          .heading {
            font-weight: 900;
            line-height: 1.22;
            max-width: 330px;
            font-size: 1.52rem;
            @media ${screen.xxsmall} {
              font-size: 2.5rem;
            }
            @media ${screen.small} {
              font-size: 3rem;
              line-height: 1.19;
            }
          }

          .description {
            max-width: 470px;
            font-size: 1.04rem;
            margin: 15px 0 26px 0;
            @media ${screen.small} {
              font-size: 1.08rem;
              margin: 15px 0 35px 0;
            }
          }

          .form {
            position: relative;
            z-index: 3;

            .input {
              background: none;
              display: block;
              color: ${mainWhite};
              width: 100%;
              border: 0;
              border-bottom: 2px ${mainWhite} solid;
              font-size: 16px;
              font-weight: 600;
              outline: none;
              border-radius: 0;
              line-height: 2;
              padding: 0 0 2px 0;
              margin: 0 0 22px 0;
              @media ${screen.xxsmall} {
                font-size: 1.1rem;
                width: 50%;
                margin: 0;
                display: inline-block;
              }

              &::placeholder {
                color: ${mainWhite};
                opacity: 0.75;
                font-weight: 400;
              }

              &--fname {
                @media ${screen.xxsmall} {
                  width: calc(50% - 20px);
                  margin: 0 20px 0 0;
                }
              }

              &--email {
                width: 100%;
                margin: 22px 0 32px 0;
                @media ${screen.xxsmall} {
                  margin: 25px 0 35px 0;
                }
              }
            }

            button {
              border: 2px ${mainWhite} solid;
              @media ${screen.withCursor} {
                &:hover {
                  border: 2px ${mainCyan} solid;
                }
              }
            }
          }
        }

        &--img {
          position: relative;
          @media ${screen.xxsmall} {
            width: 35%;
          }
          @media ${screen.xsmall} {
            width: 50%;
          }
          .img-wrapper {
            position: absolute;
            width: 190px;
            z-index: 2;
            bottom: -140px;
            right: -50px;
            @media ${screen.xxsmall} {
              min-width: 250px;
              width: 100%;
              max-width: 650px;
              right: -16px;
              bottom: -50px;
            }
            @media ${screen.xsmall} {
              min-width: 350px;
              right: -8px;
            }
            @media ${screen.small} {
              min-width: 500px;
              right: 16px;
              opacity: 1;
            }
            @media ${screen.medium} {
              min-width: 550px;
            }
            .img-subwrapper {
              .desktop-only {
                display: none;
                @media ${screen.xxsmall} {
                  display: block;
                }
              }
              .mobile-only {
                @media ${screen.xxsmall} {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .icon-close {
      position: absolute;
      top: 32px;
      z-index: 5;
      background: none;
      cursor: pointer;
      right: 32px;

      span {
        display: block;
      }

      .close-inner {
        fill: ${(props) => (props.isLoading ? mainBlack : mainWhite)};
      }

      svg {
        width: 18px;
        height: 18px;
        @media ${screen.xxsmall} {
          width: 28px;
          height: 28px;
        }
      }
    }

    #newsletter-spinner {
      position: absolute;
      background: rgba(255, 255, 255, 0.975);
      border-radius: 13px;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 4;

      .icon {
        svg {
          width: 160px;
          height: 160px;
          @media ${screen.xxsmall} {
            width: 220px;
            height: 220px;
          }
        }
      }

      .msg {
        font-size: 1.16rem;
        text-align: center;
        color: ${mainBlack};
        @media ${screen.xxsmall} {
          font-size: 2rem;
        }
        @media ${screen.xsmall} {
          font-size: 2.36rem;
        }

        .break {
          display: block;
          @media ${screen.xxsmall} {
            display: inline;
          }
        }
      }
    }
  }
`;

const NewsletterForm = ({ isBlogPage }) => {
  const data = useStaticQuery(dataQuery);
  const [isNewsletterPopup, setIsNewsletterPopup] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSucess] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");

  const isPopup = data.settings.data.newsletter_popup_enable;

  const timeDelay = data.settings.data.newsletter_popup_time_delay;

  const submitToMailchimp = async () => {
    setIsLoading(true);
    try {
      const result = await addToMailchimp(email, {
        FNAME: fname,
        LNAME: lname,
      });

      if (result) {
        setIsSucess(true);
        setFname("");
        setLname("");
        setEmail("");
      }
    } catch (e) {
      alert("Sorry, something went wrong. Please try again later!");
      setIsLoading(false);
      setFname("");
      setLname("");
      setEmail("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    submitToMailchimp();
  };

  const closePopup = () => {
    window.sessionStorage.setItem("newslettermc", true);
    setIsNewsletterPopup(false);
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("newslettermc")) {
      setIsNewsletterPopup(false);
    } else {
      setTimeout(() => {
        setIsNewsletterPopup(true);
      }, timeDelay);
    }

    const userClick = (e) => {
      if (e.target.id === "newsletter") {
        closePopup();
      }
    };
    window.addEventListener("click", userClick);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, []);

  return (
    !isBlogPage &&
    isPopup && (
      <ScrollLocky isolation={false} enabled={isNewsletterPopup}>
        <AnimatePresence>
          {isNewsletterPopup && (
            <Wrapper
              initial="hidden"
              animate={isNewsletterPopup ? "show" : "hidden"}
              variants={container}
              id="newsletter"
              isLoading={isLoading}
              exit={{ opacity: 0 }}
            >
              <div className="container">
                <div className="flex">
                  <div className="col col--form">
                    <motion.h5 className="heading" variants={item}>
                      Want to stay up to date?
                    </motion.h5>
                    <motion.p className="description" variants={item}>
                      Sign up to our free quarterly newsletter for fun facts,
                      stories and learn more about your dog’s health & safety.
                    </motion.p>

                    <motion.form
                      onSubmit={handleSubmit}
                      className="form"
                      variants={item}
                    >
                      <input
                        className="input input--fname"
                        type="text"
                        placeholder="First Name*"
                        autoComplete="off"
                        value={fname}
                        onChange={(e) => {
                          setFname(e.target.value);
                        }}
                        required
                      />
                      <input
                        className="input"
                        type="text"
                        placeholder="Last Name*"
                        autoComplete="off"
                        value={lname}
                        onChange={(e) => {
                          setLname(e.target.value);
                        }}
                        required
                      />
                      <input
                        className="input input--email"
                        type="email"
                        placeholder="Email Address*"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                      <ButtonFilled label="SUBSCRIBE" bgWhite />
                    </motion.form>
                  </div>

                  <div className="col col--img">
                    <div className="img-wrapper">
                      <motion.div className="img-subwrapper" variants={item}>
                        {!isLoading && (
                          <StaticImage
                            src="../../images/K9SWiM-Update-Popup-2.png"
                            alt="K9 Swim Dog Barking"
                            className="desktop-only"
                            placeholder="blurred"
                          />
                        )}

                        <StaticImage
                          src="../../images/K9SWiM-Update-Popup-1.png"
                          alt="K9 Swim Dog Barking"
                          className="mobile-only"
                          placeholder="blurred"
                        />
                      </motion.div>
                    </div>
                  </div>
                </div>

                <div className="icon-close" role="button" onClick={closePopup}>
                  <motion.span whileTap={{ scale: 0.98 }}>
                    <IconClose />
                  </motion.span>
                </div>

                {isLoading && (
                  <div id="newsletter-spinner">
                    {!isSuccess && (
                      <div className="icon">
                        <IconSpinner />
                      </div>
                    )}

                    {isSuccess && (
                      <p className="msg">
                        Thank you,{" "}
                        <span className="break">you’re signed up!</span>
                      </p>
                    )}
                  </div>
                )}
              </div>
            </Wrapper>
          )}
        </AnimatePresence>
      </ScrollLocky>
    )
  );
};

export default NewsletterForm;

const dataQuery = graphql`
  {
    settings: prismicMiscellaneous {
      data {
        newsletter_popup_enable
        newsletter_popup_time_delay
      }
    }
  }
`;
