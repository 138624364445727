import slugify from "slugify";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

export const toSlug = (string) => {
  return slugify(string, {
    replacement: "-", // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: true, // strip special characters except replacement, defaults to `false`
  });
};

export const isOdd = (num) => {
  return num % 2;
};

export const getFirstName = (string) => {
  if (string) {
    if (string.split(" ").length === 1) {
      return string;
    } else {
      return `${string.split(" ").slice(0, -1).join(" ")}`;
    }
  }
};

export const formatDate = (string) => {
  // const slicedDate = string.slice(0, 10);

  return dayjs(string).format("Do MMM YYYY");
};

export const reduceWords = (str, length) => {
  if (str) return str.split(" ").slice(0, length).join(" ") + "...";
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const getYoutubeId = (url) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

// used to submit form to api
export function encode(obj) {
  const formData = new FormData();

  for (const key of Object.keys(obj)) {
    formData.append(key, obj[key]);
  }
  return formData;
}
