import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  mainWhite,
  mainBlack,
  subFont,
  mainCyan,
  mainOrange,
  innerWidth,
  screen,
  transHover,
} from "../variables";
import { encode } from "../../helpers";
import { IoIosPaw } from "react-icons/io";
import IconSpinner from "../../images/svg/icon-loading.svg";
import ButtonFilled from "../../components/buttons/button-filled";
import axios from "axios";

const Wrapper = styled.section`
  min-height: 651px;
  @media ${screen.xxsmall} {
    min-height: 550px;
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    padding: 0 !important;

    .form {
      max-width: 880px;
      width: 100%;
      position: relative;
      z-index: ${(props) => (props.isLoading ? 9999 : 2)};

      label {
        display: none;
      }

      &__row {
        display: flex;
        flex-direction: column;
        @media ${screen.xxsmall} {
          flex-direction: row;
          margin: 0 0 25px 0;
        }

        textarea {
          appearance: none;
          background: none;
          color: ${mainBlack};
          width: 100%;
          border: 0;
          border: 2px ${mainCyan} solid;
          font-weight: 600;
          outline: none;
          border-radius: 0;
          padding: 12px 15px;
          height: 195px;
          resize: none;
          font-size: 16px;
          @media ${screen.xxsmall} {
            font-size: 1.06rem;
            height: 185px;
            padding: 10px 8px;
          }
          @media ${screen.small} {
            font-size: 1.1rem;
          }

          &::placeholder {
            opacity: 0.75;
            font-weight: 400;
            line-height: 1.4;
          }
        }

        &--textarea {
          margin: 0;
          @media ${screen.xxsmall} {
            margin: 35px 0 25px 0;
          }
        }
        &--checkbox {
          font-weight: 500;
          display: flex;
          align-items: center;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          margin: 22px 0 20px 0;
          font-size: 1.06rem;
          @media ${screen.xxsmall} {
            justify-content: flex-start;
            margin: 0 0 20px 0;
          }
          @media ${screen.small} {
            font-size: 1.1rem;
            font-weight: 900;
          }

          .label {
            max-width: 230px;
            line-height: 1.4;
            @media ${screen.xxsmall} {
              max-width: none;
            }
          }

          .box {
            border: 2px ${mainCyan} solid;
            width: 28px;
            height: 28px;
            margin: 0 0 0 15px;
            cursor: pointer;
            display: flex;

            svg {
              width: 24px;
              height: 24px;
              margin: auto;
              position: relative;
              opacity: 0.7;
            }
          }
        }

        &--file {
          display: flex;
          align-items: flex-end;
          margin: 0 0 20px 0;
          flex-direction: row;
          position: relative;
          @media ${screen.xxsmall} {
            align-items: center;
          }

          input {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            opacity: 0;

            &::placeholder {
              display: none;
            }
          }

          label {
            cursor: pointer;
            border-radius: 9px;
            border: 2px ${mainCyan} solid;
            padding: 11px 14px;
            display: block;
            letter-spacing: 2px;
            font-weight: 900;
            color: ${mainWhite};
            background: ${mainCyan};
            margin: 0 15px 0 0;
            transition: ${transHover};
            font-size: 0.875rem;
            z-index: 2;
            @media ${screen.xxsmall} {
              font-size: 1.08rem;
              padding: 14px 21px;
            }
            @media ${screen.small} {
              font-size: 1.1rem;
            }
            @media ${screen.withCursor} {
              &:hover {
                background: none;
                color: ${mainCyan};
              }
            }
          }

          .reminder {
            font-weight: 900;
          }
        }

        &--submit {
          display: flex;
          justify-content: flex-end;
          margin: 40px 0 0 0;
        }
      }

      &__col {
        @media ${screen.xxsmall} {
          width: 50%;
        }

        input {
          background: none;
          color: ${mainBlack};
          width: 100%;
          border: 0;
          border-bottom: 2px ${mainCyan} solid;
          font-weight: 600;
          outline: none;
          border-radius: 0;
          line-height: 2;
          font-size: 16px;
          margin: 0 0 22px 0;

          @media ${screen.xxsmall} {
            font-size: 1.06rem;
            margin: 0;
          }
          @media ${screen.small} {
            font-size: 1.1rem;
          }

          &::placeholder {
            opacity: 0.75;
            font-weight: 400;
          }
        }

        &--left {
          @media ${screen.xxsmall} {
            margin: 0 80px 0 0;
          }
        }
      }

      #contact-form-spinner {
        position: fixed;
        background: rgba(227, 246, 255, 0.93);
        border-radius: 13px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 4;
        @media ${screen.xxsmall} {
          position: absolute;
          top: 0;
          left: 0;
        }

        .icon {
          svg {
            width: 160px;
            height: 160px;
            @media ${screen.xxsmall} {
              width: 220px;
              height: 220px;
            }
          }
        }
      }

      #contact-form-success {
        position: absolute;
        background: rgba(227, 246, 255, 1);
        border-radius: 13px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 4;

        p {
          text-align: center;
          font-weight: 400;
          font-family: ${subFont};
          line-height: 1.22;
          font-size: 1.3rem;
          @media ${screen.xxsmall} {
            font-size: 2rem;
          }
          @media ${screen.small} {
            font-size: 2.9rem;
          }
          @media ${screen.large} {
            font-size: 3.2rem;
          }

          .break {
            display: block;
          }

          .name {
            color: ${mainOrange};
            font-size: 1.55rem;
            @media ${screen.xxsmall} {
              font-size: 2.1rem;
            }
            @media ${screen.small} {
              font-size: 3.1rem;
            }
            @media ${screen.large} {
              font-size: 3.4rem;
            }
          }
        }
      }
    }
  }
`;

const ContactForm = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [file, setFile] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const toggleCheckBox = () => {
    setIsChecked(!isChecked);
    if (isChecked) {
      setFile("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email: email.toLowerCase(),
      message: message,
    };

    if (isChecked) {
      if (file.size > 5005000) {
        alert("The file size is too large. File size limit is 5mb.");
      } else {
        setIsLoading(true);

        axios({
          method: "post",
          url: "https://getform.io/f/c14a805a-31a1-4c35-9eb4-2578996a8705",
          headers: { "content-type": "multipart/form-data" },
          data: encode({
            file: file,
            ...data,
          }),
        })
          .then(function (response) {
            // success
            setLastName("");
            setPhoneNumber("");
            setEmail("");
            setMessage("");
            setFile("");
            setIsLoading(false);
            setIsSuccess(true);
            setIsChecked(false);

            setTimeout(() => {
              setFirstName("");
              setIsSuccess(false);
            }, 6000);
          })
          .catch(function (error) {
            console.log(error);
            alert("Sorry, something went wrong. Please try again later!");
            setIsLoading(false);
          });
      }
    } else {
      setIsLoading(true);

      axios({
        method: "post",
        url: "https://getform.io/f/c14a805a-31a1-4c35-9eb4-2578996a8705",
        headers: { "content-type": "multipart/form-data" },
        data: encode({
          file: file,
          ...data,
        }),
      })
        .then(function (response) {
          // success

          setLastName("");
          setPhoneNumber("");
          setEmail("");
          setMessage("");
          setFile("");
          setIsLoading(false);
          setIsSuccess(true);
          setIsChecked(false);

          setTimeout(() => {
            setFirstName("");
            setIsSuccess(false);
          }, 6000);
        })
        .catch(function (error) {
          console.log(error);
          alert("Sorry, something went wrong. Please try again later!");
          setIsLoading(false);
        });
    }
  };
  return (
    <Wrapper isLoading={isLoading}>
      <div className="inner-wrapper">
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__row">
            <div className="form__col form__col--left">
              <label>First Name</label>
              <input
                type="text"
                placeholder="First Name*"
                autoComplete="off"
                name="first_name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                required
              />
            </div>
            <div className="form__col">
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Last Name*"
                name="last_name"
                autoComplete="off"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div className="form__row">
            <div className="form__col form__col--left">
              <label>Email Address</label>
              <input
                type="email"
                placeholder="Email Address*"
                name="email"
                autoComplete="off"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
            </div>
            <div className="form__col">
              <label>Phone Number</label>
              <input
                type="text"
                placeholder="Phone Number"
                name="phone_number"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="form__row form__row--textarea">
            <textarea
              placeholder="Leave us a message & we'll get back to you ASAP*"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              required
            ></textarea>
          </div>

          <div className="form__row form__row--checkbox">
            <span className="label">
              Do you have a completed vet referral form?
            </span>
            <motion.span
              className="box"
              onClick={toggleCheckBox}
              whileTap={{ scale: 0.87 }}
            >
              {isChecked && <IoIosPaw />}
            </motion.span>
          </div>
          {isChecked && (
            <>
              <motion.div
                initial={{ opacity: 0, x: 25 }}
                animate={{ opacity: 1, x: 0 }}
                className="form__row form__row--file"
              >
                <input
                  type="file"
                  name="attachment"
                  id="file"
                  required
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
                <label htmlFor="file">ATTACH DOCUMENT</label>
                <span className="reminder">max. 5mb</span>
              </motion.div>
              <span>{file && file.name}</span>
            </>
          )}
          <div className="form__row form__row--submit">
            <ButtonFilled label={"SUBMIT"} />
          </div>

          {isLoading && (
            <div id="contact-form-spinner">
              <div className="icon">
                <IconSpinner />
              </div>
            </div>
          )}

          {isSuccess && (
            <div id="contact-form-success">
              <p>
                Thanks for contacting us!{" "}
                <span className="break">
                  We will get back to you soon{" "}
                  <span className="name">{firstName}!</span>
                </span>
              </p>
            </div>
          )}
        </form>
      </div>
    </Wrapper>
  );
};

export default ContactForm;
