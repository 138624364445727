// colours used
export const mainOrange = "#FD8473";
export const lightBlue = "#E3F6FF";
export const mainPurple = "#6B5ED8";
export const lightPurple = "#947FE8";
export const mainBlack = "#262626";
export const mainWhite = "#FFFFFF";
export const dirtyWhite = "#F4F4F4";
export const mainCyan = "#23C7E5";

// website width container
export const siteWidth = "1920px";
export const innerWidth = "1396px";

export const mainFont = `'Manrope', sans-serif`;
export const subFont = `'Patrick Hand SC', cursive`;

// regex for email validation
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const transHover = `all 0.2s ease-out`;

// Media queries for devices
export const screen = {
  xxsmall: `(min-width: 600px)`,
  xsmall: `(min-width: 835px)`, // → tablet vertical
  small: `(min-width: 1160px)`, // → tablet horizontal
  medium: `(min-width: 1490px)`, // → small laptops
  large: `(min-width: 1530px)`, // → large laptop and regular size desktop
  minilarge: `(min-width: 1278px) and (max-height: 690px)`, // → laptop with short height
  xlarge: `(min-width: 1800px)`, // → large size desktop
  withCursor: `not all and (pointer: coarse)`, // → device with cursor. not touch screen devices
  dropdownWide: `(min-width: 1630px)`,
  mediumtall: `(min-height: 800px)`,
  tall: `(min-height: 1200px)`,
};
