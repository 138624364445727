import React from "react";
import { Link } from "gatsby";
import styled, { keyframes } from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import {
  mainWhite,
  lightBlue,
  subFont,
  mainPurple,
  mainCyan,
  mainBlack,
  innerWidth,
  screen,
} from "../variables";
import ContactForm from "./contact-form";
import IconArrowSquiggly from "../../images/svg/arrow-bottom-squiggly.svg";
import LogoBrand from "../../images/svg/k9-brand.svg";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import IconWheelchar from "../../images/svg/wheelchair-symbol.svg";
import IconMemberLogo from "../../images/svg/saena-member-logo.svg";
import iconPractitioner from "../../images/ACRA-Practitioner-Member.png";
import {
  IconCircleDarkPurpleMd,
  IconCircleDarkPurpleLg,
  IconCircleCyanMd,
} from "../../components/circles";
import Obfuscate from "react-obfuscate";

const floating = keyframes`
0% {
  transform: translate(0, 0px);
}

50% {
  transform: translate(0, -3px);
}

100% {
  transform: translate(0, 3px);
}
`;

const Wrapper = styled.section`
  background: ${mainWhite};
  padding: ${(props) => (props.noCirclesOnMobileFooter ? "0" : "60px 0 0 0")};
  overflow: hidden;
  @media ${screen.xxsmall} {
    padding: 0;
  }

  #footer {
    background: ${lightBlue};
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    &--details {
      display: flex;
      flex-direction: column;
      @media ${screen.xxsmall} {
        flex-direction: row;
      }
    }

    &--end {
      @media ${screen.xxsmall} {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }

  .top {
    padding: 90px 0 0 0;
    position: relative;
    @media ${screen.xxsmall} {
      padding: 75px 0 0 0;
    }

    .heading {
      font-weight: 900;
      position: relative;
      margin: 0 0 32px 0;
      line-height: 1.22;
      font-size: 2rem;
      max-width: 575px;
      @media ${screen.xxsmall} {
        font-size: 3.8rem;
        line-height: 1.09;
        margin: 0 0 65px 0;
      }
      @media ${screen.small} {
        font-size: 5rem;
        max-width: 750px;
      }

      .pointer {
        position: absolute;
        top: -35px;
        right: 20px;
        @media ${screen.xxsmall} {
          top: -12px;
          right: 23px;
        }
        @media ${screen.small} {
          top: -55px;
          right: 88px;
        }

        &__arrow {
          svg {
            width: 42px;
            transform: rotate(-105deg) scaleY(-1);
            @media ${screen.xxsmall} {
              width: 55px;
              transform: rotate(-10deg);
            }
          }
        }

        &__text {
          font-family: ${subFont};
          font-size: 1.16rem;
          font-weight: 400;
          position: absolute;
          width: 100px;
          bottom: 26px;
          right: 10px;
          @media ${screen.xxsmall} {
            bottom: -2px;
            right: -102px;
            font-size: 1.4rem;
          }
        }
      }
    }

    .icon-circle {
      &--dark-purple-md {
        display: none;
        @media ${screen.small} {
          width: 105px;
          display: block;
          left: 105px;
          top: 695px;
        }
      }

      &--dark-purple-lg {
        width: 62px;
        top: -40px;
        left: 85px;
        display: ${(props) =>
          props.noCirclesOnMobileFooter ? "none" : "block"};
        @media ${screen.xxsmall} {
          display: none;
        }
      }

      &--cyan {
        left: -60px;
        top: -50px;
        width: 130px;
        display: ${(props) =>
          props.noCirclesOnMobileFooter ? "none" : "block"};
        @media ${screen.xxsmall} {
          display: none;
        }
        @media ${screen.small} {
          width: 250px;
          display: block;
          top: 330px;
          left: 22px;
        }
      }
    }
  }

  .details {
    min-height: 245px;
    background: ${mainCyan};
    position: relative;
    margin: 180px 0 0 0;
    @media ${screen.xxsmall} {
      margin: 300px 0 0 0;
    }

    .dog-in-water {
      position: absolute;
      width: calc(200px + 77vw);
      right: 0;
      top: -150px;
      z-index: 1;
      pointer-events: none;
      @media ${screen.xxsmall} {
        width: 1130px;
        top: -232px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
      }
      @media ${screen.xsmall} {
        width: 1445px;
        top: -300px;
      }
      @media ${screen.small} {
        width: 1520px;
      }
      @media ${screen.medium} {
        top: -300px;
      }
      @media ${screen.large} {
        width: 1804px;
        top: -355px;
      }
      @media ${screen.xlarge} {
        width: 1920px;
      }

      .sm {
        @media ${screen.xxsmall} {
          display: none !important;
        }
      }

      .lg {
        display: none !important;
        @media ${screen.xxsmall} {
          display: block !important;
        }
      }
    }

    &__col {
      position: relative;
      z-index: 2;
      @media ${screen.xxsmall} {
        width: 25%;
      }
      @media ${screen.small} {
        width: 22%;
      }

      .brand-link {
        max-width: 240px;
        display: block;
        margin: 0 0 40px 0;
        @media ${screen.xxsmall} {
          max-width: 140px;
          margin: 0;
        }
        @media ${screen.xsmall} {
          max-width: 155px;
        }
        @media ${screen.small} {
          max-width: 215px;
        }

        svg {
          overflow: visible;
        }

        @media ${screen.withCursor} {
          &:hover #dog-stroke {
            animation: ${floating} 2.5s linear infinite alternate;
          }
        }
      }

      .subheading {
        font-size: 1.8em;
        font-family: ${subFont};
        margin: 0 0 14px 0;
        @media ${screen.xxsmall} {
          font-size: 1.42em;
          margin: 0 0 16px 0;
        }
      }

      .social-list {
        display: flex;
        margin: 16px 0 40px 0;
        @media ${screen.xxsmall} {
          margin: 16px 0 0 0;
        }

        li {
          a {
            color: ${mainBlack};
            display: block;
            font-size: 2.1rem;
            margin: 0 30px 0 0;
            @media ${screen.xxsmall} {
              margin: 0 25px 0 0;
              font-size: 1.7rem;
            }
            @media ${screen.withCursor} {
              &:hover {
                color: ${mainWhite};
              }
            }
          }
        }
      }

      .text-link {
        color: ${mainBlack};
        display: inline-block;
        font-size: 1.25rem;
        line-height: 1.7;
        @media ${screen.xxsmall} {
          font-size: 1rem;
        }
        @media ${screen.small} {
          font-size: 1.06rem;
        }
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainWhite};
          }
        }

        &--address {
          @media ${screen.xxsmall} {
            max-width: 260px;
          }
        }
      }

      &--brand-logo {
        @media ${screen.small} {
          margin: 0 40px 0 0;
        }
        @media ${screen.large} {
          margin: 0 60px 0 0;
        }
      }

      &--icons {
        width: 100%;
        display: flex;
        margin: 40px 0 60px 0;
        @media ${screen.xxsmall} {
          width: 18%;
          justify-content: flex-start;
          align-items: flex-end;
          flex-direction: row;
          flex-direction: column;
          margin: 0 20px 0 0;
        }
        @media ${screen.xsmall} {
        }
        @media ${screen.small} {
          align-items: flex-start;
          width: 34%;
          justify-content: flex-end;
          flex-direction: row;
        }

        .icon {
          svg {
            width: 55px;
            height: 55px;
            margin: 0 20px 0 0;
            @media ${screen.xxsmall} {
              margin: 12px 0 0 0;
              width: 60px;
              height: 60px;
            }
            @media ${screen.small} {
              width: 80px;
              height: 80px;
              margin: 0 0 0 18px;
            }
          }

          &--png {
            margin: 11px 20px 0 0;
            @media ${screen.xxsmall} {
              margin: 0;
            }
            @media ${screen.small} {
              margin: 13px 10px 0 0;
            }
            img {
              display: block;
              width: 80px;
              @media ${screen.xxsmall} {
                width: 90px;
              }
              @media ${screen.xsmall} {
              }
              @media ${screen.small} {
                width: 120px;
              }
            }
          }
        }
      }
    }
  }

  .end {
    background: ${(props) => (props.hideFooter ? lightBlue : mainWhite)};
    padding: 26px 0;
    position: relative;
    z-index: 5;
    @media ${screen.xxsmall} {
      padding: 21px 0;
    }
    @media ${screen.xsmall} {
      padding: 26px 0;
    }
    @media ${screen.small} {
      padding: 32px 0;
    }

    &__each-item {
      display: inline-block;
      font-size: 0.75rem;
      letter-spacing: 1px;
      line-height: 2.2;
      @media ${screen.xxsmall} {
        font-size: 0.6rem;
        display: block;
        line-height: 1.3;
      }
      @media ${screen.xsmall} {
        font-size: 0.7rem;
      }
      @media ${screen.small} {
        font-size: 0.82rem;
      }

      a {
        color: ${mainBlack};
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainCyan};
          }
        }
      }

      &--purple {
        a {
          color: ${mainPurple};
          font-weight: 900;
        }
      }
    }

    .pipe-symbol {
      margin: 0 11px;
      font-weight: 300;

      @media ${screen.xxsmall} {
        margin: 0 11px;
        font-weight: 500;
      }
      @media ${screen.xsmall} {
        margin: 0 19px;
      }
      @media ${screen.small} {
        margin: 0 23px;
      }

      &--desktop-only {
        display: none;
        @media ${screen.xxsmall} {
          display: inline;
        }
      }
    }
  }
`;

const Footer = ({ hideFooter, noCirclesOnMobileFooter }) => {
  const currentYear = new Date().getFullYear();

  return (
    <Wrapper
      hideFooter={hideFooter}
      noCirclesOnMobileFooter={noCirclesOnMobileFooter}
    >
      <div id="footer" data-sal="fade" data-sal-duration="700">
        {!hideFooter && (
          <>
            <div className="top">
              <div className="inner-wrapper">
                <h5 className="heading">
                  Let's talk about your furry friend!
                  <span className="pointer" role="presentation">
                    <span className="pointer__arrow">
                      <IconArrowSquiggly />
                    </span>
                    <span className="pointer__text">go fetch!</span>
                  </span>
                </h5>
                <ContactForm />
              </div>
              <IconCircleDarkPurpleLg className="icon-circle icon-circle--dark-purple-lg" />
              <IconCircleDarkPurpleMd className="icon-circle icon-circle--dark-purple-md" />
              <IconCircleCyanMd className="icon-circle icon-circle--cyan" />
            </div>
            <div className="details">
              <div className="dog-in-water">
                <StaticImage
                  className="lg"
                  src="../../images/k9-swim-australia-dog-in-the-water.png"
                  alt="K9 SWIM - Dog Swimming"
                />
                <StaticImage
                  className="sm"
                  src="../../images/k9-swim-dog-in-the-water.png"
                  alt="K9 SWIM - Dog Swimming"
                />
              </div>
              <div className="inner-wrapper inner-wrapper--details">
                <div className="details__col details__col--brand-logo">
                  <Link className="brand-link" to="/" aria-label="Home page">
                    <LogoBrand />
                  </Link>
                </div>
                <div className="details__col">
                  <h6 className="subheading">SAY HI</h6>
                  <a
                    className="text-link"
                    href="tel:1300787064"
                    aria-label="Phone number"
                  >
                    1300 787 064
                  </a>
                  <br />
                  <Obfuscate
                    className="text-link"
                    email={"info@k9swim.com.au"}
                    aria-label="Email address"
                  />

                  <ul className="social-list">
                    <li>
                      <a
                        href="https://www.facebook.com/CanineHydrotherapyCentre"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Facebook account"
                      >
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/k9-swim"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="LinkedIn account"
                      >
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/k9_swim"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Instagram account"
                      >
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details__col">
                  <h6 className="subheading">FIND US</h6>
                  <a
                    className="text-link text-link--address"
                    href="https://www.google.com/maps/place/853+Kurmond+Rd,+North+Richmond+NSW+2754/@-33.5526568,150.7519609,17z/data=!3m1!4b1!4m5!3m4!1s0x6b0d7cc677fdb14b:0x582327bf9ab9975a!8m2!3d-33.5526613!4d150.7541496"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Location address"
                  >
                    853 Kurmond Road, North Richmond, NSW Australia 2754
                  </a>
                </div>
                <div className="details__col details__col--icons">
                  <span className="icon icon--png">
                    <img src={iconPractitioner} alt="ACRA" />
                  </span>
                  <span className="icon">
                    <IconMemberLogo />
                  </span>
                  <span className="icon">
                    <IconWheelchar />
                  </span>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="end">
          <div className="inner-wrapper inner-wrapper--end">
            <span className="end__each-item">
              &copy; COPYRIGHT {currentYear} K9 SWIM
            </span>
            <span className="pipe-symbol">|</span>
            <span className="end__each-item">
              <Link to="/disclaimer/" aria-label="Disclaimer page">
                DISCLAIMER
              </Link>
            </span>
            <span className="pipe-symbol pipe-symbol--desktop-only">|</span>
            <span className="end__each-item">
              <Link to="/privacy/" aria-label="Privacy page">
                PRIVACY POLICY
              </Link>
            </span>
            <span className="pipe-symbol">|</span>
            <span className="end__each-item">
              <Link
                to="/terms-and-conditions/"
                aria-label="Terms and conditions page"
              >
                TERMS AND CONDITIONS
              </Link>
            </span>
            <span className="pipe-symbol pipe-symbol--desktop-only">|</span>
            <span className="end__each-item end__each-item--purple">
              DESIGNED BY{" "}
              <a
                href="https://www.hellohandsome.com.au"
                target="_blank"
                aria-label="Handsome creative website"
              >
                HANDSOME CREATIVE
              </a>
            </span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
