import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  mainOrange,
  mainPurple,
  mainWhite,
  siteWidth,
  innerWidth,
  screen,
} from "../variables";
import IconClose from "../../images/svg/icon-close.svg";

const Wrapper = styled.section`
  background: ${mainOrange};
  color: ${mainWhite};
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: ${siteWidth};
  display: flex;
  align-items: center;
  transform: ${(props) =>
    props.isTopNotificationOpen ? "translateY(0)" : "translateY(-60px)"};
  transition: transform 0.2s ease-in-out;
  z-index: 11;
  height: 60px;
  @media ${screen.small} {
    height: 60px;
    transform: ${(props) =>
      props.isTopNotificationOpen ? "translateY(0)" : "translateY(-60px)"};
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  p {
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 0.71rem;
    line-height: 1.5;
    padding: 0 20px;
    max-width: 605px;
    @media ${screen.xxsmall} {
      font-size: 0.95rem;
      padding: 0;
      max-width: 470px;
    }
    @media ${screen.xsmall} {
      max-width: 620px;
    }
    @media ${screen.small} {
      font-size: 1.05rem;
      max-width: none;
    }

    a {
      color: ${mainWhite};
      text-decoration: underline;
      display: block;
      @media ${screen.xxsmall} {
        display: inline;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainPurple};
        }
      }
    }
  }

  .btn-round {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    @media ${screen.xxsmall} {
      top: 50%;
      right: 58px;
      transform: translateY(-50%);
    }

    span {
      display: flex;
      border-radius: 50%;
      line-height: 20px;
      background: ${mainWhite};
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      @media ${screen.xxsmall} {
        width: 24px;
        height: 24px;
      }
      @media ${screen.small} {
        width: 28px;
        height: 28px;
      }
    }

    svg {
      width: 12px;
      @media ${screen.xxsmall} {
        width: 42%;
      }
      .close-inner {
        fill: ${mainOrange};
      }
    }
  }
`;

const TopNotification = ({
  isTopNotificationOpen,
  setIsTopNotificationOpen,
}) => {
  const data = useStaticQuery(dataQuery);

  const downloadFormUrl = data.content.data.vet_referral_form.url;

  return (
    <Wrapper isTopNotificationOpen={isTopNotificationOpen}>
      <div className="inner-wrapper">
        <p>
          TO REGISTER FOR A REHABILITATION SESSION, YOU REQUIRE A VET REFERRAL.{" "}
          <a
            href={downloadFormUrl}
            target="_blank"
            rel="noreferrer"
            aria-label="Download VET referral form"
          >
            DOWNLOAD VET REFERRAL FORM.
          </a>
        </p>
        <div
          className="btn-round"
          role="button"
          onClick={() => {
            setIsTopNotificationOpen(false);
            window.sessionStorage.setItem("downloadreferralform", true);
          }}
        >
          <motion.span
            whileTap={{ scale: 0.9 }}
            aria-label="Close notification"
          >
            <IconClose />
          </motion.span>
        </div>
      </div>
    </Wrapper>
  );
};

export default TopNotification;

const dataQuery = graphql`
  {
    content: prismicMiscellaneous {
      data {
        vet_referral_form {
          url
        }
      }
    }
  }
`;
